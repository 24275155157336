import React, {useEffect, useRef, useState} from "react";
import TranslateComponent, {translateText} from "../TranslateComponent";
import {useSelector} from "react-redux";
import axiosClient, {getCurrencyLocal, getToken} from "../../axios-client";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import PopupComponent from "../PaymentSteps/PopupCopmponent";

const KafalaePage =()=>{

    const translations = useSelector((state) => state.Storetranslate.translations);
    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [selectedCountryChose, setSelectedCountryChose] = useState({
        country: translateText('select country',translations),
        image: null,
        id: null
    });
    const getCountries = async () => {
        try {
            const response = await axiosClient.get("/country-orphans");
            const data = response.data.data;
            setCountries(data);
            setFilteredCountries(data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };
    const [isOpenCountryChose, setIsOpenCountryChose] = useState(false);
    const [searchQueryCountry, setSearchQueryCountry] = useState('');
    const countryChoseWrapperRef = useRef(null);
    const handleCountrySearch = (e) => {
        const query = e.target.value;
        setSearchQueryCountry(query);
        const filtered = countries.filter(country =>
            country && country.country && country.country.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCountries(filtered);
    };
    const toggleCountryChoseDropdown = () => setIsOpenCountryChose(!isOpenCountryChose);
    useEffect(() => {
        getCountries();

    }, []);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryChoseWrapperRef.current && !countryChoseWrapperRef.current.contains(event.target)) {
                setIsOpenCountryChose(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    const handleCountrySelectionChose = async (country) => {
        setSelectedCountryChose(country);
        setIsOpenCountryChose(false);
        setSearchQueryCountry('');
        setFilteredCountries(countries);
    };
    const {slug} = useParams();
    const [word , setWord] =useState('');
    const [data,setData]=useState([]);
    const [page,setPage]=useState(1);
    const [current_page,setCurrent_page]=useState('');
    const [total_pages,setTotal_pages]=useState('');
    const [loading,setLoading]=useState(false);
    const onChange =(e)=>{
        const {value}  = e.target;
        setWord(value);
    }
    const getData =(word = null,countryId= selectedCountryChose.id??null,newPageValue=1) =>{
        setLoading(true)
        const url =word || countryId?'Guarantee':`Guarantee/${slug}`;
        axiosClient.get(url,
            {
                params:{
                    limit:8,
                    search:word,
                    country:countryId,
                    page:newPageValue
                }
            })
            .then(res=>{
                setData(prevData => [...prevData, ...res.data.data.data]);
                console.log(res.data.data.data);
                setCurrent_page(res.data.data.pagination.current_page);
                setTotal_pages(res.data.data.pagination.total_pages);
                setLoading(false)
            })
            .catch(error=>{
                console.log(error)
                setLoading(false)
            })
    }
    useEffect(()=>{
        setData([]);
        getData();
    },[]);
    const handelSerch = (word,countryId)=>{
        setData([]);
        setPage(1)
        getData(word,countryId)
    }
    const ShowMore = ()=>{
        const newPageValue = page+1;
        setPage(newPageValue);
        getData(word,selectedCountryChose.id,newPageValue)
    }
    const showSome =() =>{
        const newPageValue = 1;
        setPage(newPageValue);
        setData([]);
        getData(word,selectedCountryChose.id,newPageValue)

    }
    const handelGetAllData =()=>{
        setData([]);
        setWord('');
        setSelectedCountryChose(
            {
                country: translateText('select country',translations),
                image: null,
                id: null
            }
        )
        getData('',null,1);
    }

    const [price ,setPrice]=useState('');
    const [showPopup , setShowPopup] = useState(false);
    const [type,setType]=useState('');

    const handelChangePrice = (e)=>{
        const {value} = e.target;

        if (value > 0){
            setPrice(value);
            setShowPopup(true);
            setType('guarantee');
            localStorage.setItem('price',value);
            dispatchEvent(new Event('priceChanged'));
        }else{
            setPrice('');
        }

    }
    const [orphanId , setOrphanId] =useState('');
    const forPay=(pricePay)=>{
        // eslint-disable-next-line no-mixed-operators
        if(getToken() && getToken()!=='' || getToken() !==null){
            showPopup&&(  document.getElementById('my_modal_4_guarantee').showModal() )
            if(!pricePay){
                toast.error(translateText('Please enter the donation amount',translations));
            }
        }else{
            toast.info(translateText('You must be logged in',translations))
        }
    }
    window.addEventListener('removeForPayDirect',()=>{
        setPrice('');
    })
    return (
        <section className="aytam-list container max-w-7xl mx-auto px-2 mt-4">
            <div className="flex flex-col text-start ">
                <div className="text-5xl font-bold text-zinc-900 max-md:max-w-full">
                    <TranslateComponent word={'كفالات'}/>
                </div>
                <div className="mt-8 w-full text-lg text-zinc-900 max-md:max-w-full">
                    <TranslateComponent word={'KfalatDescription'}/>
                </div>
            </div>
            <div className={'grid grid-cols-1 mt-4'}>
                <div className={'card bg-white'}>
                    <div className="filters px-5 justify-between pt-[1.3rem]">
                        <div className={'flex justify-between'}>
                            <div className="relative w-1/2 lg:w-auto lg:mx-5" ref={countryChoseWrapperRef}>
                                <div
                                    className="flex items-center select select-bordered w-full max-w-xs"
                                    onClick={toggleCountryChoseDropdown}>
                                    {selectedCountryChose.image ? (
                                        <img
                                            loading="lazy"
                                            src={selectedCountryChose.image}
                                            className="shrink-0 self-stretch aspect-square w-[30px] mx-2 object-contain mask mask-circle"
                                            alt={'aytam'}
                                        />
                                    ) : ('')}

                                    <span className='font-bold text-black pt-1'>{selectedCountryChose.country}</span>
                                </div>
                                {isOpenCountryChose && (
                                    <div
                                        className="absolute mt-1 bg-white border rounded-lg shadow-sm max-h-44 z-30 w-full overflow-y-scroll overflow-x-hidden">
                                        <input
                                            type="text"
                                            value={searchQueryCountry}
                                            onChange={handleCountrySearch}
                                            placeholder="Search Country..."
                                            className="w-full p-2 border-b focus:outline-none"
                                        />
                                        {filteredCountries.map((country) => (
                                            <div key={country.id}
                                                 className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer"
                                                 onClick={() => handleCountrySelectionChose(country)}
                                            >
                                                <img src={country.image} alt={country.country}
                                                     className="w-5 aspect-square mr-2 rounded-lg"/>
                                                <span className='font-bold text-black'>{country.country}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className='form-group w-1/2 lg:w-auto '>
                                <input
                                    type="text"
                                    name="word"
                                    value={word}
                                    onChange={onChange}
                                    placeholder={'ادخل كلمة البحث'}
                                    className={'w-full serchInputKafalate serchInputKafalate-bordered p-[0.7rem]'}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end items-end w-full lg:w-auto">
                            <div className="form-group flex justify-end items-end">
                                {
                                    word!=='' | selectedCountryChose.id !==null ?(
                                        <div className="m-3" role={'button'} onClick={handelGetAllData}>X</div>
                                    ):('')
                                }
                                <button type="button"
                                        onClick={() => handelSerch(word, selectedCountryChose.id)}
                                        className="btn btn-submit bg-blue-500 text-white p-2 rounded-md flex items-center justify-center">
                                    <span className="spinner-border spinner-border-sm hidden mr-2"><TranslateComponent
                                        word={''}/></span>
                                    <span className="text pt-1 flex items-center px=-">
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" className="m-2">
                                        <path
                                            d="M21 11.5C21 13.3789 20.4428 15.2156 19.399 16.7779C18.3551 18.3402 16.8714 19.5578 15.1355 20.2769C13.3996 20.9959 11.4895 21.184 9.64665 20.8175C7.80383 20.4509 6.11109 19.5461 4.78249 18.2175C3.45389 16.8889 2.5491 15.1962 2.18254 13.3534C1.81598 11.5105 2.00412 9.60041 2.72315 7.86451C3.44218 6.12861 4.65982 4.64491 6.22209 3.60104C7.78435 2.55717 9.62108 2 11.5 2C14.0196 2 16.4359 3.00089 18.2175 4.78249C19.9991 6.56408 21 8.98044 21 11.5V11.5Z"
                                            stroke="#fff" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"><TranslateComponent word={''}/></path>
                                        <path d="M22 22L20 20" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"
                                              strokeLinejoin="round"><TranslateComponent word={''}/></path>
                                    </svg>
                                   <TranslateComponent word={'Search now'}/>
                               </span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className=" grid gap-4 mt-5 grid-cols-1 lg:grid-cols-4 " id="aytam-list">
                    {data && data.map((item, index) => (
                        <div key={index}>
                            <div className={'col-span-1'}>
                                <div className={'content bg-white rounded-2xl p-5 '}>
                                    <div className="mt-5 text-2xl font-bold text-center text-neutral-900">
                                        {item.name}
                                    </div>
                                    <div className="mt-5 text-sm text-center text-zinc-600">
                                <span
                                    className="relative text-pink-800 p-3 bg-red-100 rounded-xl border-pink-800 border-solid badge">
                                    {item.country}
                                    <img
                                        loading="lazy"
                                        src={item.image}
                                        className="h-5 mask mask-circle px-2 "
                                        alt={item.name}
                                    />
                                </span>
                                    </div>
                                    <img
                                        alt={item.name}
                                        src={item.image}
                                        className="mt-5 w-full blur aspect-[1.37]"
                                    />

                                    <div className="self-center mt-5 text-base font-bold  text-stone-900">
                                        {item.guaranteePurpose} : <TranslateComponent word={'guaranteePurpose'}/>
                                    </div>

                                    {item.is_disabled === 1 && (
                                        <div className="self-center mt-5 text-base font-bold  text-stone-900">
                                            <TranslateComponent word={'is_disabled'}/>
                                        </div>
                                    )}

                                    {item.is_hafiz === 1 && (
                                        <div className="self-center mt-5 text-base font-bold  text-stone-900">
                                            <TranslateComponent word={'is_hafiz'}/>
                                        </div>
                                    )}

                                    {item.is_orphan === 1 && (
                                        <div className="self-center mt-5 text-base font-bold  text-stone-900">
                                            <TranslateComponent word={'is_orphan'}/>
                                        </div>
                                    )}

                                    {item.is_patient === 1 && (
                                        <div className="self-center mt-5 text-base font-bold  text-stone-900">
                                            <TranslateComponent word={'is_patient'}/>
                                        </div>
                                    )}
                                    <div className="flex gap-2 mt-3.5">
                                        <div
                                            className="flex flex-1 gap-2 justify-between py-1 pr-2 pl-1 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid">
                                            <div
                                                role="button"
                                                onClick={() => {
                                                    forPay(price);setOrphanId(item.id)
                                                }}
                                                className="justify-center px-3.5 py-2 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm">

                                                <TranslateComponent word={'donation'}/>
                                            </div>
                                            <div className="my-auto text-base leading-6  text-gray-500">
                                                <input
                                                    className=" w-full outline-none text-lg indent-1 m-1"
                                                    value={price}
                                                    onChange={handelChangePrice}
                                                    type="text" placeholder={translateText("the value", translations)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center">
                    {loading ? (
                        <div className="flex justify-center items-center">
                            <span className="text-black loading loading-dots loading-lg"><TranslateComponent word={''}/></span>
                        </div>
                    ) : (
                        <>
                            <div>
                                {data && data.length > 0 && current_page < total_pages && (
                                    <div className="text-center">
                                        <button
                                            className="border rounded-lg px-5 py-3 mx-4 my-4 bg-white hover:bg-blue-700 hover:text-white"
                                            onClick={ShowMore}>
                                            <TranslateComponent word={'Show more'}/>
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div>
                            {page > 1 && (
                                    <div className="text-center">
                                        <button className="border rounded-lg px-5 py-3 mx-4 my-4 bg-white hover:bg-blue-700 hover:text-white" onClick={showSome}>
                                            <TranslateComponent word={'Show a little'}/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </>


                    )}
                </div>
            </div>
            {showPopup&&(
                <PopupComponent type={type} setShowPopup={setShowPopup} projectId={orphanId} price={price} symbol={getCurrencyLocal() && getCurrencyLocal().code} handelChangePrice={handelChangePrice}/>
            )}
        </section>
    );
}

export default KafalaePage;