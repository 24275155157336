import React from 'react';

const ValidateMsg = ({ msg, setOpen, open }) => {
    const handleClose = () => {
        setOpen(!open);
    };
    return (
        <div
            className={`fixed inset-0 flex items-center justify-center ${open ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}

        >
            {/*<div className="bg-white border border-gray-300 rounded-lg shadow-lg" ref={validateRef}>*/}
            <div className="bg-white border border-gray-300 rounded-lg shadow-lg">
                <div className="flex justify-end px-4 py-2">
                    <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 focus:outline-none">
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>
                <div className="p-4">
                    <p className="text-gray-700">{msg}</p>
                </div>
            </div>
        </div>
    );
};

export default ValidateMsg;
