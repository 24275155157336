import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const Header=createSlice({
  name:'StoreHeader',
  initialState:{
    Headers:[],
  },
  reducers:{
    updateHeaderData:(state,action)=>{
      state.Headers=action.payload;
    }
  }
})
export const {updateHeaderData}=Header.actions;

export const fetchHeaders = () => async (dispatch) => {
    try {
        const response = await axiosClient.get(`/header`);
        const headersData = response.data.data;
        dispatch(updateHeaderData(headersData));
    } catch (error) {
        console.error('Error:', error);
    }
};
export default Header.reducer;