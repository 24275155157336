import { createSlice } from '@reduxjs/toolkit';

const Page = createSlice({
    name: 'StorePage',
    initialState: {
        content: [],
    },
    reducers: {
        updatePageState: (state, action) => {
            state.content = action.payload;
        }
    },
});

export const { updatePageState } = Page.actions;

export default Page.reducer;