import React, { useState } from 'react'
import axiosClient, { getLocalLang } from '../axios-client';
import 'reactjs-popup/dist/index.css';
import TranslateComponent from "./TranslateComponent";

const Galleries = React.lazy(() => import("./Media/Galleries"));
const Videos = React.lazy(() => import("./Media/Videos"));

function SliderShow() {
    const [galleries, setGallery] = useState([]);
    const [firstThree, setFirstThree] = useState([]);
    const [remaining, setRemaining] = useState([]);
    const [page, setPage] = useState(1);
    const [loadData, setLoadData] = useState(true);
    const [pageTotal, setPageTotal] = useState(1);
    const [loader,setLoader]=useState(false);
    const[videos,setVideos]=useState([])
    const [activeSection,setActiveSection]=useState('galleries');
    const [isNotPageHome,setisNotPageHome]=useState(false);
    const [lastGalley,setLastGallry]=useState('');

    const getGalleries = (page = null) => {
        setLoader(true);
        axiosClient.get('galleries', {
            params: {
                limit: 7,
                page: page,
                lang: getLocalLang() && getLocalLang().code
            }
        })
            .then(response => {
                const allData = response.data.data.data;
                const currentPath = window.location.href;
                const parts = currentPath.split('/');
                const lastPart = parts[parts.length - 1];
                if (lastPart !== 'more-gallery' ) {
                    if(allData.length > 6){
                        setGallery(allData.slice(0, 6));
                        setLastGallry(allData[6]);
                        setLoader(false);
                        setisNotPageHome(false);
                    }else{
                        setPageTotal(response.data.data.pagination.total_pages);
                        setGallery(prevData => [...prevData, ...allData]);
                        setLoader(false);
                        setisNotPageHome(false)
                    }
                }else{
                    setPageTotal(response.data.data.pagination.total_pages);
                    setGallery(prevData => [...prevData, ...allData]);
                    setLoader(false);
                    setisNotPageHome(true)
                }

            })
            .catch(error => {
                console.log(error);
                setLoader(false);
            })
    }
    const getVideos = (page = null) => {
        setLoader(true);
        axiosClient.get('videos', {
            params: {
                limit: 7,
                page: page,
                lang: getLocalLang() && getLocalLang().code
            }
        })
            .then(response => {
                const allData = response.data.data;
                // setPageTotal(response.data.data.pagination.total_pages);
                setVideos(prevData => [...prevData, ...allData]);
                setLoader(false);

            })
            .catch(error => {
                console.log(error);
                setLoader(false);
            })
    }
    const handleLoadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        if(activeSection==='galleries') {
            getGalleries(nextPage);
        }else{
            getVideos(nextPage)
        }
    }
    const ShowSome =()=>{
        setPage(1);
        if(activeSection==='galleries'){
            setGallery([]);
            getGalleries(1);
        }else{
            setVideos([]);
            getVideos(1)
        }
    }
    const handelActiveSection=(name)=>{
        setActiveSection(name);
    }
    const PaginationStatus =activeSection==='galleries'?galleries && galleries.length>0 && page<pageTotal:videos && videos.length>0 && page<pageTotal;
    return (
        <div className='container max-w-7xl mx-auto'>
            <div className="flex flex-col px-5">
                <div className="flex flex-col">
                    <div className="self-start text-lg md:text-5xl font-bold   text-zinc-900">
                        <TranslateComponent word={'Media Center'}/>
                    </div>
                    <div className="flex gap-5 justify-between mt-8 w-full max-md:flex-wrap max-md:max-w-full">
                        {isNotPageHome &&(
                            <div className="flex gap-0 text-lg leading-6  whitespace-nowrap rounded-2xl border border-gray-300 border-solid shadow-sm">
                                <div
                                    role={'button'}
                                    onClick={()=>handelActiveSection('galleries')}
                                    className={`flex-1 justify-center px-4 py-2.5 font-medium text-black rounded-2xl  border-r${activeSection==='galleries'&&'border-gray-300 bg-slate-200'}  border-solid max-md:px-5`}>
                                    <TranslateComponent word={'photo'}/>
                                </div>
                                <div
                                    role={'button'}
                                    onClick={()=>handelActiveSection('videos')}
                                    className={`flex-1 justify-center px-4 py-2.5 font-bold text-blue-900 rounded-2xl  border-r${activeSection==='videos'&&'border-gray-300 bg-slate-200'} border-solid  max-md:px-5`}>
                                    <TranslateComponent word={'video'}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                    {activeSection==='galleries'&&(
                        <Galleries
                            data={galleries}
                            firstThree={firstThree}
                            remaining={remaining}
                            loadData={loadData}
                            setFirstThree={setFirstThree}
                            setRemaining={setRemaining}
                            setLoadData={setLoadData}
                            setLoader={setLoader}
                            getGalleries={getGalleries}
                            activeSection={activeSection}
                            lastGalley={lastGalley}
                            isNotPageHome={isNotPageHome}
                        />
                    )}
                    {activeSection==='videos'&&(
                        <Videos
                        videos={videos}
                        loadData={loadData}
                        setVideos={setVideos}
                        setLoadData={setLoadData}
                        setLoader={setLoader}
                        getVideos={getVideos}
                        activeSection={activeSection}
                        />
                    )}
                    {/*for first*/}
                    {loader? (
                        <div className="flex justify-center items-center">
                            <span className="text-black loading loading-dots loading-lg"></span>
                        </div>
                    ): (
                        <div className="flex justify-center mt-5">
                            <div>
                                {PaginationStatus&& (
                                    <div className="text-center">
                                        <button className="border rounded-lg px-5 py-3 mx-4"
                                                onClick={handleLoadMore}>
                                        <TranslateComponent word={'Show more'}/>


                                        </button>
                                    </div>
                                )}
                            </div>
                            <div>
                                {page > 1 && (
                                    <div className="text-center">
                                        <button className="border rounded-lg px-5 py-3" onClick={ShowSome}>
                                            <TranslateComponent word={'Show a little'}/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
            </div>
        </div>

)
}

export default SliderShow