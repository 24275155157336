import React, {useEffect, useState} from 'react';
import TranslateComponent from "./TranslateComponent";
import {Link} from "react-router-dom";
import axios from "axios";
import axiosClient from "../axios-client";
const Kafalat = () => {
    const [data , setData]=useState();
    const getTypes = () =>{
        axiosClient.get('Guarantee/type')
            .then(res=>{
                setData(res.data.data);
            })
            .catch(error=>{
                console.log(error);
            })
    }

    useEffect(()=>{
        getTypes();
    },[])
    // builder.io
    return (
        <div className="container max-w-7xl mx-auto mt-8 px-2">
            <div className="flex  flex-col text-start ">
                <div className="text-5xl font-bold text-zinc-900 max-md:max-w-full">
                    <TranslateComponent word={'كفالات'}/>
                </div>
                <div className="mt-8 w-full text-lg text-zinc-900 max-md:max-w-full">
                    <TranslateComponent word={'KfalatDescription'}/>
                </div>
            </div>

            <div className=" py-4">
                <div className="">
                    <div className={'grid grid-cols-1 lg:grid-cols-3'}>
                        {data?.map((item, index) => {
                            return (
                                <div index={index}
                                     className="mr-2 mt-4 p-[4rem] col-span-1 bg-white hover:bg-blue-100 rounded-xl flex justify-center items-center">
                                    <Link to={`/kafale/${item.slug}`}>
                                        <div>
                                            <img
                                                src={item.image ?? "https://alkhaircharity.org/img/GuaranteeType/5fec6af5225c2.png"}
                                                alt={item.name}
                                                className={''}
                                            />
                                        </div>
                                        <div className="text-center text-2xl mt-2 ">{item.name}</div>
                                    </Link>
                                </div>
                            )
                        })}
                        <div
                             className="mr-2 mt-4 p-[4rem] col-span-1 bg-white hover:bg-blue-100 rounded-xl flex justify-center items-center">
                            <Link to={`/kafale/orphan`}>
                                <div>
                                    <img
                                        src={"https://alkhaircharity.org/img/GuaranteeType/5fec6af5225c2.png"}
                                        alt={'orphan'}
                                        className={''}
                                    />
                                </div>
                                <div className="text-center text-2xl mt-2 ">{'orphan'}</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kafalat;
