import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const donationSlice = createSlice({
    name: 'StoreDonation',
    initialState: {
        DonationData: [],
        loading: false,
        error: null
    },
    reducers: {
        getDonationDataSuccess: (state, action) => {
            state.DonationData = action.payload;
            state.loading = false;
            state.error = null;
        },
        getDonationDataError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getDonationDataPending: (state) => {
            state.loading = true;
        }
    }
});

export const { getDonationDataSuccess, getDonationDataError, getDonationDataPending } = donationSlice.actions;

export const fetchDonationData = (paramsData) => async (dispatch) => {
    dispatch(getDonationDataPending());
    try {
        const response = await axiosClient.get('/order-details', {
            params: {
                suscribtion:paramsData
            }
        });
        dispatch(getDonationDataSuccess(response.data.data.data));
    } catch (error) {
        if(error?.response?.data?.message){
            dispatch(getDonationDataError(error.response.data.message))
        }
        console.log(error)
    }
};

export default donationSlice.reducer;
