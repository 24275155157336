import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const Countries = createSlice({
    name: 'StoreCountries',
    initialState: {
        countries: []
    },
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload;
        }
    }
});

export const { setCountries } = Countries.actions;

export const fetchCountries = () => async (dispatch) => {
    try {
        const response = await axiosClient.get("/countries");
        dispatch(setCountries(response.data.data));
    } catch (error) {
        console.error("Error fetching countries:", error);
    }
};

export default Countries.reducer;
