import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {getCurrencyLocal, getToken} from "../../axios-client";
import TranslateComponent, {translateText} from "../TranslateComponent";
import {fetchCreateCart, fetchGetData} from "../Cart/Cart";
import {CartIcon} from "../../Images/svgs";
import LocalCart from "../Cart/LocalCart";



const DonationCardSidbar = ({ post, price, setShowPopup, translations }) => {


    const forPay = (pricePay) => {
        if (getToken() && getToken() !== '') {
            if (pricePay) {
                setShowPopup(true);
                document.getElementById('my_modal_4_orphan').showModal();
            } else {
                toast.error(translateText('Enter the amount to donate', translations));
            }
        } else {
            toast.info(translateText('You must be logged in', translations));
        }
    }



    return (
        <div className="md:col-span-1 col-span-1 max-h-full">
            <div className="flex flex-col px-4 py-5 mb-2 w-full bg-white rounded-lg border border-solid border-black border-opacity-20">
                <div className="flex text-2xl font-bold tracking-wide leading-10 text-center">
                    {translateText('Donation now')}
                </div>


                <div className="flex gap-2 px-3.5 py-2.5 mt-2 text-sm leading-6   rounded-lg border border-gray-300 shadow-sm bg-stone-50">
                    <span ><TranslateComponent word={'Every Month Will Donate'}/> </span>
                    <span className="col-span-1 text-blue-700 font-bold">{price} {getCurrencyLocal()?.code}</span>
                </div>
                <div className="flex gap-3 mt-4">
                    <button
                        onClick={() => forPay(price)}
                        className="flex-1 px-3.5 py-2 text-sm font-medium leading-5 bg-amber-300 rounded-lg shadow-sm text-neutral-900 cursor-pointer"
                    >
                        <TranslateComponent word={'Sponsor me'}/> (
                        {price} {getCurrencyLocal()?.code}
                        )
                    </button>

                </div>

            </div>
        </div>
    );
}

export default DonationCardSidbar;
