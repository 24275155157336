import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import { translateText } from "../Components/TranslateComponent";

const Orphan = createSlice({

  name: 'StoreOrphan',
  initialState: {
    Orphans: [],
    loader:false,
  },
  reducers: {
    updateOrphanData: (state, action) => {
      state.Orphans = action.payload;
    },
    updateOrphanState: (state, action) => {
      state.loader = action.payload;
    }
  }

});

export const { updateOrphanData ,updateOrphanState} = Orphan.actions;

export const featchGetOrphan =   (dispatch) =>{
  dispatch(updateOrphanState(true))
   axiosClient.get('/my-orphans')
      .then(response=>{
        dispatch(updateOrphanData(response.data.data));
        dispatch(updateOrphanState(false))
      })
      .catch(error=>{
          if(error?.response?.data?.message){
              toast.error(translateText(error?.response?.data?.message))
          }
          dispatch(updateOrphanState(false))
        })

}
export default Orphan.reducer;