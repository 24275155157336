import { createSlice } from '@reduxjs/toolkit';
import axiosClient from "../axios-client";

const Lang = createSlice({
    name: 'Storelang',
    initialState: {
        langs: [],
        icon:''
    },
    reducers: {
        updateLangState: (state,action) => {
            state.langs = action.payload;
        },
        updateIconState: (state,action) => {
            state.icon = action.payload;
        }
    }
});

export const { updateLangState,updateIconState } = Lang.actions;

export const fetchLang = (dispatch)=>{
    axiosClient.get('languages')
        .then(response=>{
            dispatch(updateLangState(response.data.data));
            dispatch(updateIconState(response.data.data[0].icon))
        })
        .catch(error =>{
            console.log('ERROR :' + error)
        })
}
export default  Lang.reducer;