import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useNavigate} from "react-router-dom";
import TranslateComponent from "./TranslateComponent";

import thanksMsg from "../../src/Images/thanksMsg.png"

const AfterPayThanksMsg = ({fromContactAs}) =>{
    const navigate = useNavigate();
    const To =()=>{
        navigate('/');
        if(!fromContactAs){
            dispatchEvent(new Event('dontHaveCart'));
        }
    }
    return (
            <div
                className="flex flex-col justify-center items-center pb-20 mt-44 max-w-full bg-white rounded-2xl w-[441px] max-md:mt-10">
                <LazyLoadImage
                    loading="lazy"
                    src={thanksMsg}
                    className="self-stretch w-full aspect-[1.12] max-md:max-w-full"
                    alt="afterPay"
                />
                <div className="mt-5 text-2xl font-bold text-center text-black">
                    {fromContactAs?(
                        <TranslateComponent word={'Thank you very much for your Message'} />
                    ):(
                        <TranslateComponent word={'Thank you very much for your kind donation'}/>
                    )}
                    <br/>
                    <span className=""><TranslateComponent word={'Your support is greatly appreciated and appreciated'}/></span>
                </div>
                <div
                    role="button"
                    onClick={To}
                    className="flex justify-center items-center px-3.5 py-2 mt-5 max-w-full text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm w-[358px] max-md:px-5">
                    <TranslateComponent word={'Browse projects'}/>
                </div>
            </div>
    )
}
export default AfterPayThanksMsg;