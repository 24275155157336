import { createSlice } from '@reduxjs/toolkit';
import axiosClient from "../axios-client";

const Currencies = createSlice({
    name:"StoreCurrency",
    initialState:{
        currencies:[],
        // symbol:''
    },
    reducers:{
      updateCurrenciesState :(state,action)=>{
          state.currencies = action.payload;
      },
      // updateSymbolState :(state,action)=>{
      //   state.currencies = action.payload;
      // }
    }
});
export const {updateCurrenciesState}=Currencies.actions;

export const fetchCurrency = async (dispatch)=>{
     await axiosClient.get('/currencies')
        .then(response=>{
            dispatch(updateCurrenciesState(response.data.data));
            // dispatch(updateSymbolState(response.data.data[0].symbol));
        })
        .catch(error =>{
            console.log('ERROR :' + error)
        })
}
export default Currencies.reducer;