import React from 'react';
import { ErrorIcon } from './Images/svgs';
import {Link} from "react-router-dom";
import TranslateComponent, { translateText } from './Components/TranslateComponent';
import {useDispatch,useSelector} from "react-redux";
import Navbar from './Components/Layouts/Navbar';
import SecondNavbar from './Components/Layouts/SecondNavbar';
import Footer from './Components/Layouts/Footer';
import FooterNav from './Components/Layouts/FooterNav';
import Breadcrumbs from './Components/Partials/Breadcrumbs';
const ErrorBoundary=() => {
    const translations = useSelector((state) => state.Storetranslate.translations);

  return(

    <>
    <div className='bg-[#EAEFFB] h-full pb-[100px] md:pb-0'>
        <div className='border-b bg-[#FFFFFF] '>
            <Navbar/>
        </div>

            <SecondNavbar/>
            <Breadcrumbs/>
            <div className='relative container max-w-7xl mx-auto px-2'>
              <div className="flex flex-col items-center justify-center login-y">
                <div className="flex flex-col p-16 mx-auto w-full bg-white rounded-3xl border border-solid border-neutral-200 max-w-[480px] max-md:px-5">
                    <div className="flex flex-col justify-center px-10 text-sm text-black bg-white rounded-xl max-w-[486px] max-md:px-5">
                        <div className={'mx-auto'}>
                            <ErrorIcon width={100}/>
                        </div>
                        <div className="flex flex-col">
                            <div className="mt-6 text-2xl font-bold text-center">
                                <TranslateComponent word={'oops'}/>!
                            </div>
                            <div className="mt-2 font-light text-center ">
                                <TranslateComponent word={'sorry a Technical Error Occurred Which will be Fixed as Soon as Possible'}/>
                            </div>

                                <Link to={'/'}>
                                    <div className="flex flex-col justify-center items-center px-3.5 py-2 mt-6 font-semibold text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm leading-[143%] max-md:px-5">
                                        {translateText('Back To Home',translations)}
                                    </div>
                                </Link>

                        </div>
                    </div>
                </div>
              </div>
            </div>
        <Footer/>

    </div>
    <FooterNav />
</>

)
}
export default ErrorBoundary;
