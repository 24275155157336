import React from 'react';
import TranslateComponent from "../../TranslateComponent";

const OtherPayOrphan =({other_pay})=>{
  return(
    <>
    <h1><TranslateComponent word={'OtherPayOrphan'} /></h1>
    <div className="overflow-x-scroll rounded-xl hide-scroll">
     <table className="w-full whitespace-nowrap ">
          <thead>
              <tr className={'px-5 py-5 font-bold bg-gray-200 rounded-xl border border-solid backdrop-blur-[10px] border-zinc-300 w-full'}>
                  <th className="px-2 py-5 text-start"><TranslateComponent  word={'price'}/></th>
                  <th className="px-2 text-start"><TranslateComponent word={'orderStatus'}/></th>
                  <th className="px-2 text-start"><TranslateComponent word={'time'} /></th>
              </tr>
          </thead>
          <tbody className={''}>
            {other_pay?.map((item,index)=>(
              <tr key={index} className={'p-5 mt-2.5 rounded-xl border border-solid backdrop-blur-[10px] bg-neutral-100 border-zinc-300 w-full'}>
                <td className={'px-2 py-5 '}>{'item.price'}</td>
                <td className={'px-2 '}>{'item.orderStatus'}</td>
                <td className={'px-2 '}>{'item.time'}</td>
              </tr>
            ))}
          </tbody>
      </table>
     </div>
    </>
  )

  }
  export default OtherPayOrphan;