import React, {useEffect, useRef} from 'react';
import { toggleDropdown } from '../../Managment/Slice';
import { useDispatch } from 'react-redux';
import { useStateContext } from '../../Providers/ContextProvider';
import { Link } from 'react-router-dom';

function CurrencyDropDown({ links, isOpen, dropdownId, onSelect ,setSymbol ,toggleDropDown}) {
    const dropCuurency=useRef(null);
    useEffect(() => {
        const handleClickOutside=(event)=>{
            if(dropCuurency.current && !dropCuurency.current.contains(event.target)){
                dispatch(toggleDropdown({ dropdownId }));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    const { setSelectedItems } = useStateContext();
    const dispatch = useDispatch();
    const handleClick = (name,code_id,Curvalue,code) => {
        setSelectedItems(prevItems => {
            const newItems = [...prevItems];
            newItems[dropdownId] = name;
            return newItems;
        });
        onSelect(name,code_id,Curvalue,code)
        dispatch(toggleDropdown({ dropdownId }));
    };

    if (!isOpen) return null;


    return (
        <div ref={dropCuurency} id={`dropdownHover${dropdownId}`} className="z-[100] text-right left-1 md:left-0 right-0 absolute top-12 z-10 bg-white dark:bg-white divide-y divide-gray-100 rounded-lg shadow w-44 max-h-40 md:max-h-60 overflow-y-scroll">

            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownHoverButton${dropdownId}`}>
                {links?.map((link, index) => (
                    <li key={index}>

                            <a
                                onClick={() => { handleClick(link.name,link.id,link.value,link.code); setSymbol(link.symbols); }}
                                className="cursor-pointer block px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 text-black dark:text-black dark:hover:text-white hover:text-white"
                            >
                                {link.name}
                            </a>

                    </li>

                ))}
            </ul>
        </div>
    );
}

export default CurrencyDropDown;
