import {createSlice} from '@reduxjs/toolkit';
import axiosClient, {getCurrencyLocal, getLocalLang} from "../axios-client";

const SerchResult = createSlice({
    name: 'StoreSerch',
    initialState: {
        content: [],
        ShowDropdown: false,
        loader:false,
        allContent:[],
    },
    reducers: {
        handelSerchState: (state, action) => {
            state.content = [...state.content, ...action.payload];
        },
        resetSerchState: (state,action) => {
            state.content = action.payload;
        },
        handelSerchStatus: (state, action) => {
            state.ShowDropdown = action.payload;
        },
        changeLoaderStatus: (state, action) => {
            state.loader = action.payload;
        },
        handelAllContent:(state,action)=>{
            state.allContent=action.payload;
        }
    },

});
export const { handelSerchState, handelSerchStatus,resetSerchState,changeLoaderStatus,handelAllContent } = SerchResult.actions;

export const feachSerch = (searchWord, campaign) => async (dispatch) => {
    dispatch(resetSerchState([]));
    dispatch(handelSerchStatus(true));
    if (campaign === 'all') {
        axiosClient.get(campaign,{
            params: {
                search: searchWord?? ''
            }
        })
            .then(response => {
                const combinedResults = response.data.data;
                dispatch(resetSerchState(combinedResults));
                dispatch(changeLoaderStatus(false));
            })
            .catch(error => {
                console.log(error);
                dispatch(changeLoaderStatus(false));

            });
    } else {
        axiosClient.get(campaign, {
            params: {
                search: searchWord ?? ''
            }
        })
            .then(response => {
                dispatch(handelSerchState(response.data.data.data));
                dispatch(changeLoaderStatus(false));

            })
            .catch(error => {
                console.log(error);
                dispatch(changeLoaderStatus(false));

            });
    }
};

export const feachFilter = (word,sort,page)=> async (dispatch)=>{
    axiosClient.get('projects', {
        params: {
            lang:getLocalLang() && getLocalLang().code,
            limit: 4,
            page: page,
            currency: getCurrencyLocal()&&getCurrencyLocal().id,
            search:word,
            sort:sort
        }
    })
        .then(response => {
            dispatch(handelSerchState(response.data.data.data));
        })
        .catch(error => {
            console.error('Error:', error);
        });
}
export const showSome = (word,sort)=> async (dispatch)=>{
    // dispatch(resetSerchState([]));

    axiosClient.get('projects', {
        params: {
            lang:getLocalLang() && getLocalLang().code,
            limit: 4,
            page: 1,
            currency: getCurrencyLocal()&&getCurrencyLocal().id,
            search:word,
            sort:sort
        }
    })
        .then(response => {
            dispatch(resetSerchState([]));
            //
            dispatch(handelSerchState(response.data.data.data));
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

export default SerchResult.reducer;
