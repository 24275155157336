import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import gift1 from '../../Images/gift1.png'
import { useRef , useState , useEffect} from 'react';
import {Arrow, SaudiFlag} from '../../Images/svgs';
import {useSelector} from "react-redux";
import axiosClient,{getCurrencyLocal} from '../../axios-client';
import { toast } from 'react-toastify';
import TranslateComponent, { translateText } from '../TranslateComponent';
import PopupComponent from "../PaymentSteps/PopupCopmponent";

const GiftPopup = ({changeGiftDropStatus,orphanId})=>{
    const translations = useSelector((state) => state.Storetranslate.translations);
    const DataAll =useSelector(state=>state.StoreCountries.countries)
    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [loadServer,setLoadeServer]=useState(true);
    const [selectedCountry, setSelectedCountry] = useState({
        num_code:"+966",
        image: 'https://project.alkhair.weblayer.info/assets/flags/ar.svg',
        id: null
    });
    const getCountries = async () => {
        try {
            const data = DataAll;
            setCountries(data);
            setFilteredCountries(data);
            setSelectedCountry(data[0] || {name: 'No countries',num_code:"+966", image: 'https://project.alkhair.weblayer.info/assets/flags/ar.svg', id: null});
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };
    const [isOpenCountry, setIsOpenCountry] = useState(false);
    const [searchQueryCountry, setSearchQueryCountry] = useState('');
    const countryWrapperRef = useRef(null);
    const handleCountrySearch = (e) => {
        const query = e.target.value;
        setSearchQueryCountry(query);
        const filtered = countries.filter(country => country.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredCountries(filtered);
    };
    const toggleCountryDropdown = () => setIsOpenCountry(!isOpenCountry);
    useEffect(() => {
        if(countries.length > 0) {
            setCountries(countries);
        }else{
            if(loadServer){
                getCountries();
                setLoadeServer(false);
            }
        }
    }, [countries]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryWrapperRef.current && !countryWrapperRef.current.contains(event.target)) {
                setIsOpenCountry(false);
            }

        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    const handleCountrySelection = async (country) => {
        setSelectedCountry(country);
        setIsOpenCountry(false);
        setSearchQueryCountry('');
        setFilteredCountries(countries);
    };

    const [phone,setPhone]=useState('');
    const [email,setEmail]=useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [value,setValue] =useState(1);
    const [loader,setLoader]=useState(false);
    const handleChangePhone =(e)=>{
        const{value}=e.target;
        setPhone(value);
    }
    const handleChangeEmail =(e)=>{
        const{value}=e.target;
        setEmail(value);
    }
    const handelChangeValue=(workAs)=>{
        if(workAs==='add'){
            setValue(value+1)
        }else if(value>1){
            setValue(value-1)
        }
    };
    const [orphanGifts ,setOrphanGifts]=useState([]);
    const getOrphanGift=()=>{
        setLoader(true);
        axiosClient.get('/orphan-gifts')
        .then(response=>{
            setOrphanGifts(response.data.data);
            setLoader(false);
        })
        .catch(error=>{
            console.log(error);
            setLoader(false);
        })
    }
    const handleCheckboxChange = (id, price, isChecked) => {
        if (isChecked) {
            setSelectedItems([...selectedItems, { id, price }]);
        } else {
            setSelectedItems(selectedItems.filter(item => item.id !== id));
        }
    }
    useEffect(()=>{
        getOrphanGift();
    },[])
    useEffect(() => {
        let totalPrice = 0;
        selectedItems.forEach(item => {
            if(selectedItems.length===1){
                totalPrice += item.price*value;
            }else{
            totalPrice += item.price;
            }
        });
        setTotal(totalPrice);
    }, [selectedItems,value]);
    const [showPopupPay ,setShowPopupPay]=useState(false);
    const selectedIds = selectedItems.map(item => item.id);
    const [orderId,setOrderId]=useState('');
    const handelSaveOrder=()=>{
        if(phone){
            if(email.includes("@")){
                const formData = new FormData();
                formData.append('currency_id',getCurrencyLocal().id);
                formData.append('price',total);
                formData.append('phone',selectedCountry.num_code+phone);
                formData.append('email',email);
                formData.append('gifts',JSON.stringify(selectedIds));
                if(orphanId){
                    formData.append('orphan_id',orphanId);
                }
                if(selectedItems.length===1){
                    formData.append('qty',value);
                }

                if(selectedItems.length>0){
                    axiosClient.post('/git-orphan',formData)
                   .then(response=>{
                    if(response.status){
                        setOrderId(response.data.data);
                        setShowPopupPay(true);
                    }
                   })
                   .catch(error=>{
                        console.log(error)
                    })
                }
            }else{
                toast.error(translateText('Email is not valid',translations))
            }
        }else{
            if(!email.includes("@")){
                toast.error(translateText('please Inter Regit Phone and email',translations))
            }else{
                toast.error(translateText('please Inter Regit Phone',translations))
            }
        }
    }
    useEffect(()=>{
        if(orderId && showPopupPay){
            document.getElementById('my_modal_4_giftOrphanDonation').showModal();
        }
    },[orderId,showPopupPay]);

    return(
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
            <div className="bg-white p-5 shadow-lg rounded w-[100%] lg:w-[1000px] lg:mx-w-[1000px]  ">
                    <button onClick={changeGiftDropStatus} type="button">
                        <LazyLoadImage src="https://alkhaircharity.org/orphan_asset/img/close.svg" alt="close"/>
                    </button>
                <div className="mt-11 mx-8 mb-4 bg-blue-900 px-8 py-8 text-white rounded-xl shadow-lg">
                    <h3 className={'text-center'}><TranslateComponent word={'Send Gifts To Orphans'}/> </h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                        <div className="col-span-1">
                            {loader?(
                                <div className="flex justify-center items-center">
                                    <span className="text-white loading loading-dots loading-lg"></span>
                                </div>
                            ):(
                                orphanGifts.map((item,index)=>(
                                    <label key={index} className='flex justify-start mt-2'>
                                        <input
                                            onChange={(e) => handleCheckboxChange(item.id,item.price, e.target.checked)}
                                            type="checkbox"
                                            className="checkbox checkbox-success mx-1 bg-white"
                                        />
                                        <span >{item.name}</span>
                                    </label>
                                ))
                            )}

                        </div>
                        <div className="col-span-1">
                            <div className="flex gap-2 w-[100%] px-3.5 py-2.5 mt-1.5 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm  ">
                                <div className="flex gap-2.5 w-[75%] items-center text-base font-medium text-black">

                                    <div className="flex flex-1 gap-2 my-auto text-sm tracking-widest leading-6 text-gray-500 ">
                                        <div className="flex-1 max-md:max-w-full">
                                            <input type="number" name="phone" className="w-full" value={phone}
                                                onChange={handleChangePhone}
                                                placeholder="XXXXXXXX"/>
                                        </div>

                                    </div>
                                </div>
                                       {/*This For CountiesChose DropDown */}
                                <div className="relative w-[25%]" ref={countryWrapperRef}>
                                    <div
                                        className="flex gap-2 justify-end text-sm font-medium leading-6 text-right bg-white   text-neutral-900 cursor-pointer"
                                        onClick={toggleCountryDropdown}>
                                        <Arrow className={"pt-2"}/>
                                        <span className='font-bold text-black pt-1'>{selectedCountry.num_code}</span>

                                        <LazyLoadImage
                                            loading="lazy"
                                            src={selectedCountry ? selectedCountry.image : <SaudiFlag/>}
                                            className="shrink-0 self-stretch aspect-square w-[30px] mask mask-circle object-cover"
                                            alt={'Gift'}
                                        />

                                    </div>
                                    {isOpenCountry && (
                                        <div
                                            className="absolute mt-1 left-0 bg-white border rounded-lg shadow-sm max-h-44 z-30 w-full min-w-[200px] overflow-y-scroll overflow-x-hidden">
                                            <input
                                                type="text"
                                                value={searchQueryCountry}
                                                onChange={handleCountrySearch}
                                                placeholder="Search Country..."
                                                className="w-full p-2 border-b text-black focus:outline-none"
                                            />
                                            {filteredCountries.map((country) => (
                                                <div key={country.id}
                                                    className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleCountrySelection(country)}
                                                >
                                                    <LazyLoadImage src={country.image} alt={country.name}
                                                        className="w-5 aspect-square mr-2 rounded-lg"/>
                                                    <span className='font-bold text-black'>{country.name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='flex gap-2  px-3.5 py-2.5 mt-1.5 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm  max-md:flex-wrap'>
                                <div className="flex flex-1 gap-2 my-auto text-sm tracking-widest leading-6 text-gray-500 max-md:flex-wrap">
                                    <div className="flex-1 max-md:max-w-full">
                                        <input type="email" name="email" className="w-full" value={email}
                                            onChange={handleChangeEmail}
                                            placeholder="example@gmail.com"/>
                                    </div>
                                </div>
                            </div>
                            <p className='form-text-gift lg:whitespace-nowrap'>
                                <TranslateComponent word={'You must enter your email and phone number to receive documentation of the gift'}/>
                            </p>


                            <div className='flex mt-2 justify-center items-center'>
                                {selectedItems.length===1&&
                                (
                                    <div className='flex bg-white p-1 mx-1 rounded-lg shadow-xl'>
                                        <button onClick={()=>handelChangeValue('add')}  className="up_count btn btn-count bg-yellow-400 border-none hover:bg-yellow-300 hover:text-white" title="Up">
                                            +
                                        </button>
                                        <div  className=' bg-white px-3 flex justify-center items-center rounded-lg text-black text-center disabled'>
                                            {value}
                                        </div>
                                        <button onClick={()=>handelChangeValue('remove')} className="down_count btn btn-count  bg-yellow-400 border-none hover:bg-yellow-300 " title="Down">
                                            -
                                        </button>
                                    </div>
                                )}
                                    {selectedItems.length>=1&&(
                                        <span className="flex items-center mx-1 rounded rounded-l-none border-0 p-1 font-bold text-grey-100 ">
                                        <button
                                        onClick={handelSaveOrder}
                                            className="bg-blue-700 text-white hover:bg-yellow-300 hover:text-black text-sm p-4 rounded">

                                        <TranslateComponent word={'donation'}/>

                                        </button>
                                        </span>
                                    )}
                                    </div>

                                    {total>0&&(
                                    <h2 className='text-center mt-3'><TranslateComponent word={'The value of the gift'}/>{total}{getCurrencyLocal().code}</h2>
                                )}
                        </div>
                        <div className="col-span-1 flex justify-center items-center">
                            <div className=''>
                                <LazyLoadImage
                                    loading="lazy"
                                    src={gift1}
                                    className="w-44 max-w-full aspect-[0.87]"
                                    alt='gift'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPopupPay&&(
                <PopupComponent type={'giftOrphanDonation'} subscription={false} setShowPopup={setShowPopupPay} price={total} projectId={orderId}  symbol={getCurrencyLocal().name}/>
            )}
        </div>
    );
}
export default GiftPopup;