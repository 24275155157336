import { createSlice } from '@reduxjs/toolkit';
import {saveCurrencyLocal, savelangLocal} from "../axios-client";

const MAX_INDEX = 10;

const Slice = createSlice({
    name: 'dropdown',
    initialState: {
      showlists: Array.from({ length: MAX_INDEX }, () => false),
      selectedValues: [],
      selectedCurrency:[],
      token: localStorage.getItem('token') || null,
    },
    reducers: {
          toggleDropdown: (state, action) => {
            const { index } = action.payload;
            state.showlists = state.showlists.map((showlist, i) => (i === index ? !showlist : false));
          },
          setSelectedValue: (state, action) => {
            const { index, value ,type,code_id,LangIcon_CurValue,code} = action.payload;
              if (type==='lang'){
                  state.selectedValues[index] = value;
                  savelangLocal({name:value,code:code_id,icon:LangIcon_CurValue});
              }else if(type==='currency'){
                  state.selectedCurrency[index] = value;
                  saveCurrencyLocal({id:code_id,name:value,value:LangIcon_CurValue,code:code}?? {id:"1",name:"USD",value:LangIcon_CurValue,code:'USD'});
              }
          },
        setToken: (state, action) => {
          state.token = action.payload;
          localStorage.setItem('token', action.payload); // Store token in localStorage
          },
        clearToken: (state) => {
          state.token = null;
          localStorage.removeItem('token'); // Remove token from localStorage
    },
  },
});
export const { toggleDropdown, setSelectedValue,setToken, clearToken } = Slice.actions;

export default Slice.reducer;
