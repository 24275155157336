
import { Helmet } from 'react-helmet';
import axiosClient, { getLocalLang } from '../axios-client';
import { useState,useEffect } from 'react';
import {useSelector} from "react-redux";
import { translateText } from './TranslateComponent';
import { format } from 'date-fns';

const SEOComponent=({
  title,
  description,
  image,
})=>{
  const translations = useSelector((state) => state.Storetranslate.translations);
  const [country, setCountry] = useState('');
  const footerData  =useSelector(state=>state.StoreFooter.data);
  const haderData = useSelector(state=>state.StoreHeader.Headers);
  const currentUrl = decodeURIComponent(window.location.href);
  const time =new Date();
  // const currentUrl = window.location.href;
  const currentYear = new Date().getFullYear();
  const formattedDate = format(time, 'yyyy-MM-dd HH:mm:ss');
  const fetchCountry = async () => {
    try {
      const response = await axiosClient.get('https://ipapi.co/json/');
      setCountry(response.data.country_name);
    } catch (err) {
        console.log(err);
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  const translatedTitle = title?translateText(title,translations) +' | '+ translateText('Al Khair Foundation', translations):translateText('Al Khair Foundation', translations);
  const translatedDescription = description ?? translateText('Al-Khair Voluntary Foundation', translations);
  const siteName = haderData?.og_site_name ?? translateText('Al Khair Foundation', translations);
  const logoImage = image ?? haderData.logo_header??'https://alkhairproject.s3.amazonaws.com/logo/header/kz5cMz7wAC43rhB3dNPiHnLYeJgdi8dTWSgW4bTz.png';

  return (
    <Helmet>
      <html lang={getLocalLang() && getLocalLang()?.code} />
      <title>{translatedTitle}</title>
      <link rel="canonical" href={currentUrl} />
      <meta name="description" content={translatedDescription} />
      <meta property="og:image" content={logoImage} />
      <meta itemprop="image" content={logoImage} />
      <meta name='subject' content={translatedTitle} />
      <meta name='copyright' content={currentYear} />
      <meta name='language' content={getLocalLang() && getLocalLang()?.code ? getLocalLang()?.code : 'AR'} />
      <meta name='url' content={currentUrl} />
      <meta itemprop='name' content={siteName} />
      <meta property="og:fb" content={translatedTitle} />
      <meta property="og:title" content={translatedTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={translatedDescription} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:email" content={footerData.data?.email??"email@gmail.com"} />
      <meta property="og:phone_number" content={footerData.data?.phone??'000'} />
      <meta property="og:country-name" content={country??'alkhir'} />
      <meta name="twitter:site" content={translatedTitle} />
      <meta name="twitter:title" content={translatedTitle} />
      <meta name="twitter:description" content={translatedDescription} />
      <meta name="twitter:creator" content="@author_handle" />
      <meta name="twitter:image" content={logoImage} />
      <meta property="article:modified_time" content={formattedDate} />
    </Helmet>
  )
}

export default SEOComponent;