import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const WishListSlice = createSlice({
    name: 'StoreWishList',
    initialState: {
        wishLists: [],
        loader: false,
        error: null
    },
    reducers: {
        getWishListSuccess: (state, action) => {
            state.loader = false;
            state.wishLists = action.payload;
            state.error = null;
        },
        getWishListFailure: (state, action) => {
            state.loader = false;
            state.error = action.payload;
        }
    }
});

export const { getWishListSuccess, getWishListFailure } = WishListSlice.actions;

export const fetchWishList = () => async dispatch => {
    dispatch({ type: 'StoreWishList/getWishList/pending' });
    try {
        const response = await axiosClient.get('/wishlists');
        dispatch(getWishListSuccess(response.data.data));
    } catch (error) {
        dispatch(getWishListFailure(error.response.data.message));
    }
};


export default WishListSlice.reducer;
