import {Arrow} from "../Images/svgs";
import React from "react";
import {getCurrencyLocal} from "../axios-client";
import TranslateComponent, { translateText } from "./TranslateComponent";
import {useSelector} from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

import InputValidetion from "./InputValidetion";
import FileUploader from "./FileUploader";

const FormCard = ({
  newPrice,
  methodId,
  optionType,
  handleChange,
  handelChangeNumber,
  Changedate,
  hadelChangeCvc,
  email,
  cardNumber,
  expiry,
  cvc,
  countryWrapperRef,
  toggleCountryDropdown,
  numCode,
  isOpenCountry,
  searchQueryCountry,
  handleCountrySearch,
  filteredCountries,
  handleCountrySelection,
  countryimage,
  phone,
  selectedFile,
  selectedFileShow,
  handleFileChange,
  errorsV,
})=>{
    const translations = useSelector((state) => state.Storetranslate.translations);
  const offlineMethods = JSON.parse(localStorage.getItem('offlineMethods'));

  const methodIdNumber = Number(methodId);
  if (offlineMethods && offlineMethods.length > 0) {
    const offlineData = offlineMethods.find((item) => item.id === methodIdNumber);
  } else {
    console.log(translateText('No offline payment methods found',translations));
  }

    return(
        <>
                {optionType===1&& ( <div>
                        <div className="flex gap-1.5  mt-4 text-base">
                            <div className="font-medium text-slate-700"></div>
                            <div className="">
                            <TranslateComponent word={'“Email is required to ensure a secure donation process.”'}/>
                            </div>
                        </div>
                        <div
                            className="justify-center px-3.5 py-2.5 mt-1.5 text-sm leading-6 ight text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full">
                            <input name="email" onChange={handleChange} value={email}
                                   placeholder=" example@gmail.com "/>

                        </div>

                  <InputValidetion msg={errorsV.emailData}/>
                        <div className=" mt-5 text-base font-medium leading-5">
                            <div className="">
                                <span className="font-medium ">
                            <TranslateComponent word={'phone number'}/>
                                    </span>
                            <TranslateComponent word={'“Required to ensure a safe donation process.”'}/>

                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-2  px-3.5 py-2.5 mt-1.5 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm  max-md:flex-wrap">
                            <div className="col-span-2 gap-2.5  w-full  items-center text-base font-medium text-black">
                                <div
                                    className="flex flex-1 gap-2 my-auto text-sm tracking-widest leading-6 text-gray-500 max-md:flex-wrap">
                                    <div className="flex-1 max-md:max-w-full">
                                        <input type="number" name="phone" value={phone}
                                               onChange={handleChange}
                                               placeholder="966 458 45711"/>


                                    </div>
                                </div>
                            </div>
                            {/*This For CountiesChose DropDown */}
                            <div className="relative col-span-1" ref={countryWrapperRef}>
                                <div
                                    className="flex gap-2 justify-end text-sm font-medium leading-6     text-neutral-900 cursor-pointer"
                                    onClick={toggleCountryDropdown}>
                                    <Arrow className={"pt-2"}/>
                                    <span className='font-bold text-black pt-1'>{numCode}</span>

                                    <LazyLoadImage
                                        loading="lazy"
                                        src={countryimage}
                                        className="shrink-0 self-stretch aspect-square w-[30px] mask mask-circle object-cover"
                                        alt={'country'}
                                    />
                                </div>
                                {isOpenCountry && (
                                    <div
                                        className="absolute mt-1 bg-white border rounded-lg shadow-sm max-h-44 z-30 w-full overflow-y-scroll overflow-x-hidden">
                                        <input
                                            type="text"
                                            value={searchQueryCountry}
                                            onChange={handleCountrySearch}
                                            placeholder="Search Country..."
                                            className="w-full p-2 border-b focus:outline-none"
                                        />
                                        {filteredCountries.map((country) => (
                                            <div key={country.id}
                                                 className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer"
                                                 onClick={() => handleCountrySelection(country)}
                                            >
                                                <LazyLoadImage src={country.image} alt={country.name}
                                                     className="w-5 aspect-square mr-2 rounded-lg"/>
                                                <span className='font-bold text-black'>{country.name}</span>
                                            </div>
                                        ))}

                                    </div>
                                )}

                            </div>

                        </div>
                     <InputValidetion msg={errorsV.phone}/>
                        <div className=" mt-4 text-base font-medium ">
                        <TranslateComponent word={'card number'}/>


                        </div>
                        <div
                            className="justify-center px-3.5 py-2.5 mt-1.5 text-sm leading-6 ight text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full">
                            <input type="number" onChange={handelChangeNumber} value={cardNumber} name="cardNumber"
                                   placeholder="211 456 478 222"/>

                        </div>

                  <InputValidetion msg={errorsV.cardNumber}/>
                        <div className="grid grid-cols-2 gap-4 mt-4 max-md:flex-wrap">
                            <div className=" col-span-1 ">
                                <div className="text-base font-medium ">
                                    <TranslateComponent word={'Expiry date'}/>
                                </div>
                                <div

                                    className="justify-center px-3.5 py-2.5 mt-1.5 text-sm leading-6 ight text-gray-500 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                                    <input onChange={Changedate} value={expiry}  className={'w-[100%]'} placeholder={'01/2*'}/>
                                </div>
                                <InputValidetion msg={errorsV.expiry}/>
                            </div>
                            <div className=" col-span-1  whitespace-nowrap">
                                <div className="text-base font-medium ">
                                    CVC
                                </div>
                                <div
                                    className=" px-3.5 py-2.5 mt-1.5 text-sm  text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                                    <input
                                        onChange={hadelChangeCvc}
                                        value={cvc}
                                        placeholder="***"
                                        className={'w-[100%]'}
                                    />
                                </div>
                                <InputValidetion msg={errorsV.cvc}/>

                            </div>
                        </div>
                        <div
                            className=" mt-12 text-xl font-bold tracking-wide leading-4 ight  max-md:mt-10">
                        <TranslateComponent word={'Donation details'}/>


                        </div>
                        <div
                            className="flex flex-col justify-center py-6 mt-4 text-base tracking-wide leading-4 rounded-lg border-2 border-solid bg-blue-900 bg-opacity-10 border-blue-900 border-opacity-50 max-md:max-w-full">
                            <div className="flex gap-4 px-4 text-zinc-600 max-md:flex-wrap">
                                <div className="flex-1 font-medium ight"><TranslateComponent word={'Your Donation'}/> </div>

                                <div className=" font-bold">{newPrice}{getCurrencyLocal()&&getCurrencyLocal().name}</div>
                            </div>


                        </div>
                    </div> )}
                {optionType===2&& ( <div className="mt-5">
                <TranslateComponent word={'Confirmation and approval'}/>


                    </div> )}
                {optionType===3&& (
                    <div className="mt-5">
                      <div>
                        {offlineMethods.map((item, index) => {
                          return (
                              <div key={index} className="card bg-white shadow-md rounded-md p-6">
                                <div className="flex items-center justify-between">
                                  <LazyLoadImage
                                      className="w-32 h-32 rounded-md object-cover"
                                      src={item.image}
                                      alt="Album"
                                  />
                                  <div className="ml-4">
                                    <h2 className="text-xl font-bold text-gray-800">{item.name}</h2>
                                    <div className="mt-2">
                                      <h3 className="text-gray-600 font-semibold">
                                        <TranslateComponent word={'Bank name'}/>

                                        : <span
                                            className="text-gray-800">{item.derails[0].bank_name}</span>
                                      </h3>
                                      <h3 className="text-gray-600 font-semibold">
                                      <TranslateComponent word={'account name'}/>

                                        : <span
                                            className="text-gray-800">{item.derails[0].account_name}</span>
                                      </h3>
                                      <h3 className="text-gray-600 font-semibold">
                                      <TranslateComponent word={'account number'}/>

                                        : <span
                                            className="text-gray-800">{item.derails[0].account_number}</span>
                                      </h3>
                                      <h3 className="text-gray-600 font-semibold">
                                      <TranslateComponent word={'Routing number'}/>

                                        : <span
                                            className="text-gray-800">{item.derails[0].routing_number}</span>
                                      </h3>
                                      <h3 className="text-gray-600 font-semibold">
                                      <TranslateComponent word={'IBAN number'}/>

                                        : <span
                                            className="text-gray-800">{item.derails[0].iban}</span>
                                      </h3>
                                    </div>
                                  </div>

                                </div>
                              </div>
                          )
                        })}
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                        <div className="col-span-1 lg:col-span-1 pt-8">
                          <div
                              className="max-w-[9rem] justify-center px-3.5 py-2 mt-3 rounded-lg border border-gray-200 border-solid">
                            <FileUploader handleFile={handleFileChange} text={translateText('Upload File',translations)}/>
                          </div>
                        </div>
                        <div className="col-span-1 lg:col-span-1 pt-8">
                          {selectedFile && selectedFile.type === 'application/pdf' ? (
                              <object
                                  data={selectedFileShow}
                                  type="application/pdf"
                                  width="100%"
                                  height="500px"
                                  aria-label={translateText("Upload a new file",translations)}
                              >
                                <p>
                                <TranslateComponent word={'The file cannot be displayed. Download it to view.'}/>

                                </p>
                              </object>
                          ) : (
                              <LazyLoadImage
                                  src={selectedFileShow ?? "https://cdn.icon-icons.com/icons2/3001/PNG/512/default_filetype_file_empty_document_icon_187718.png"}
                                  alt={translateText("Image preview",translations)}
                                  className="object-cover w-full h-[15.5rem] rounded-lg shadow-md"
                              />
                          )}
                        </div>
                      </div>

                    </div>
                )}
        </>
    )
}
export default FormCard;