import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from 'react-router-dom';
import axiosClient from "../../axios-client";
import TranslateComponent, {translateText} from "../TranslateComponent";
import {feachSerch} from "../../Managment/SerchResult";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SEOComponent from "../SEOComponent";

const ProjectCard = React.lazy(() => import("../Partials/ProjectCard"));
const SideBar = React.lazy(() => import("../Partials/SideBar"));

function ProjectsAll() {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const data = useSelector(state => state.StoreSerch.content);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [projects, setProjects] = useState(data || []);
    const [pageTotal,setPageTotal] = useState('');
    const [currentPage,setCurrentPage] = useState('');
    const [selectedSort, setSelectedSort] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [serchWord, setSerchWord] = useState('');
    const [page, setPage] = useState(1);


    const handelWishListEvent = () => {
        dispatch(feachSerch(serchWord, selectedSort));
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchData = async (word = '', sort = '', page = 1) => {
        try {
            const response = await axiosClient.get(`/projects`, {
                params: { search: word, sort, page , limit:5 }
            });
            const pagination =response.data.data.pagination;
            const data =response.data.data.data;
            setPageTotal(pagination.total_pages);
            setCurrentPage(pagination.current_page);
            setProjects(prevProjects => [...prevProjects, ...data]);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if(!projects.length>0){
            fetchData();
        }
    }, []);

    const handelSort = (AsType) => {
        setIsOpen(!isOpen);
        setSelectedSort(AsType);
        setProjects([]);
        fetchData(serchWord, AsType);
    };

    const handelChangeSerchword = (e) => {
        const { value } = e.target;
        setSerchWord(value);
    };

    const clear = () => {
        setSelectedSort('');
        setSerchWord('');
    };

    const handelFiter = (word, sort) => {
        setPage(1);
        setProjects([]);
        fetchData(word, sort);
        clear();
    };

    const loadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchData(serchWord, selectedSort, nextPage);
    };

    const ShowSome = () => {
        setPage(1);
        setProjects([]);
        fetchData(serchWord, selectedSort);
    };
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <>
            <SEOComponent
                title={'Projects'}
            />

            <div className="flex flex-col ">
                <button
                    onClick={handleBack}
                    className="my-[30px] flex  text-base  text-zinc-900"
                >
                    <LazyLoadImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bf5ebe68718414aa09032adc1faa72bfc5b65e67fd3efb0465b740e24356ce36?"
                        className="h-[20px] aspect-square" alt="Back" />
                    <div className=""><TranslateComponent word={"Back"}/> </div>
                </button>
                <div className="self-start text-5xl font-bold  text-zinc-900">
                    {serchWord && (
                        <>
                            <h4 className={'text-lg'}><TranslateComponent word={'Search results for'}/>{serchWord}</h4>
                        </>
                    )}
                </div>
                <div className="mt-7 w-full max-md:max-w-full">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
                        <div className="col-span-3 flex flex-col max-md:mt-6">
                            <div className="flex flex-col px-2">
                                <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                                    <div className="flex flex-col px-2 pt-8 pb-2 bg-white rounded-2xl max-md:max-w-full">
                                        <div
                                            className="flex gap-5 justify-between mx-4 text-xl font-light text-right text-black max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
                                            <LazyLoadImage
                                                loading="lazy"
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e46e9a087bcdbff985f89bd5f6c19fc1853ba2112f213f0ba23516d1bf137ae?"
                                                className="w-9 aspect-square"
                                                alt="Projects"
                                            />
                                            <input
                                                value={serchWord}
                                                onChange={handelChangeSerchword}
                                                className="flex-auto my-auto max-md:max-w-full outline-none text-black"
                                                placeholder={translateText('serach',translations)}
                                            />
                                            <button
                                                onClick={() => handelFiter(serchWord, selectedSort)}
                                                className="justify-center md: px-5 py-2.5 text-base font-bold text-center text-white whitespace-nowrap bg-blue-900 rounded-lg shadow-sm"
                                            >
                                                {translateText('Search',translations)}
                                            </button>
                                        </div>
                                        <div
                                            className="flex flex-col justify-center px-4 py-5 mt-8 bg-white bg-opacity-70 max-md:max-w-full">
                                            <div
                                                className="flex gap-5 justify-between px-1.5 py-1 w-full max-md:flex-wrap max-md:max-w-full">
                                                <div
                                                    className="flex gap-5 text-base font-semibold text-center text-black whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                                                    <div className="dropdown" ref={dropdownRef}>
                                                        <div
                                                            tabIndex={0}
                                                            role="button"
                                                            onClick={() => setIsOpen(!isOpen)}
                                                            className="relative flex gap-2 justify-between px-5 py-2.5 rounded-xl border border-gray-200 border-solid"
                                                        >
                                                            <LazyLoadImage
                                                                loading="lazy"
                                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb7ee6ff94730c90c98c4a099d1f2cb2832f51627f858ba2d8f597d5673c6e12?"
                                                                className="w-6 aspect-square"
                                                                alt="Projects"
                                                            />
                                                            <TranslateComponent word={'Sort'}/>
                                                        </div>
                                                        {isOpen && (
                                                            <ul
                                                                tabIndex={0}
                                                                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                                                            >
                                                                <li>
                                                                    <button onClick={() => handelSort('new')}>new
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => handelSort('old')}>old</button>
                                                                </li>
                                                            </ul>
                                                        )}
                                                    </div>
                                                    {selectedSort && (
                                                        <div
                                                            className="flex gap-2 justify-between px-5 py-2.5 font-bold bg-amber-300 rounded-xl">
                                                            <LazyLoadImage
                                                                onClick={() => setSelectedSort('')}
                                                                loading="lazy"
                                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6e5d75473811092c17de9e320447a7ccef2e12b5cc08e57a3df34dc59b87aee?"
                                                                className="w-4 aspect-square"
                                                                alt="Projects"/>
                                                            <span className="text-sm">
                                                                <TranslateComponent
                                                                    word={'Fillter By'}/>: {selectedSort === 'old' ? translateText('the oldest',translations) : translateText('the most recent',translations)}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <button onClick={clear}
                                                        className="my-auto text-base font-light leading-8 text-right text-zinc-900">
                                                    <TranslateComponent word={'Delete All'}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col px-2 ">
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-4 mt-5">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                                        {projects.length > 0 && projects.map((project, index) => (
                                            <ProjectCard handelWishListEvent={handelWishListEvent} project={project}
                                                        key={`project_${index}`}/>
                                        ))}
                                        {!projects.length > 0 &&(
                                            'No Projects'
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center mt-5">
                                <div>
                                    {currentPage<pageTotal && (
                                        <div className="text-center">
                                            <button className="border rounded-lg px-5 py-3 mx-4"
                                                    onClick={loadMore}>
                                                        <TranslateComponent word={'Show more'} />

                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {page > 1 && (
                                        <div className="text-center">
                                            <button className="border rounded-lg px-5 py-3" onClick={ShowSome}>
                                                <TranslateComponent word={'Show a little'}/>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="md:col-span-1 sm:col-span-1 col-span-1">

                            <SideBar projectShow={false}/>
                        </div>

                    </div>
                </div>
            </div>
        </>

    );
}

export default ProjectsAll;
