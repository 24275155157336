import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import {Oval} from 'react-loader-spinner';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import useFetchOrphan from "./hooks/useFetchOrphan";
import SEOComponent from "../SEOComponent";
import {getCurrencyLocal, truncateText} from "../../axios-client";
import DonationCardSidbar from "./DonationCardSidbar";
import SideBar from "../Partials/SideBar";
import PopupComponent from "../PaymentSteps/PopupCopmponent";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import {Facebook, Twitter, WhatsappBlack} from "../../Images/svgs";
import TranslateComponent from "../TranslateComponent";


function OrphanShow() {
    const {slug} = useParams();

    const translations = useSelector((state) => state.Storetranslate.translations);

    const [showPopup, setShowPopup] = useState(true);

    const {post, loading, error} = useFetchOrphan(slug);

    let price=50*getCurrencyLocal()?.value??1
    let type='orphan'
    useEffect(() => {
        setShowPopup(true)
    }, [showPopup]);



    if (loading) {
        return <div className="grid justify-center text-center mb-[100px]"><Oval/></div>;
    }

    if (error) {
        toast.error(error);
        return null;
    }

    if (!post) {
        return null;
    }

    const fullUrl = decodeURIComponent(window.location.href);
    const shareWhatsapp = (data, url) => {
        window.location.href = `whatsapp://send?text=${encodeURIComponent(data)}%0A${encodeURIComponent(url)}`;
    }

    return (
        <>
            <SEOComponent
                title={post.name}
                description={truncateText(post.description, 169)}
                image={post.image}
            />
            {/*<BackButton handleBack={handleBack} slug={slug} translations={translations} />*/}
            <div className="grow justify-center max-md:max-w-full">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mt-6">
                    <div className="col-span-1 md:col-span-3">
                        <div className="grid gap-8">
                            <div
                                className="grid gap-6 p-8 bg-white rounded-2xl border border-solid border-neutral-200 max-md:px-5 max-md:max-w-full">
                                 <span
                                     className="relative text-pink-800 p-3 bg-red-100 rounded-xl border-pink-800 border-solid badge">
                                    {post.country}

                                     <LazyLoadImage
                                         loading="lazy"
                                         src={post.image}
                                         className="h-5 rounded "
                                         alt={post.name}
                                     />
                                </span>
                                <h1 className="text-4xl font-bold text-center text-zinc-900">{post.name}</h1>
                                <h5 className={'text-center'}><span   className="relative text-black p-3 font-bold bg-amber-300 rounded-xl border-amber-300 border-solid badge mx-1"><TranslateComponent word={'Classroom:'}/> {post.classroom} </span>
                                    <span   className="relative text-white p-3 bg-blue-800 font-bold rounded-xl border-blue-800 border-solid badge"><TranslateComponent word={'Age:'}/> {post.age} </span></h5>
                                <LazyLoadImage
                                    loading="lazy"
                                    src={post.image}
                                    className="mt-8 w-full aspect-[1.82] max-md:max-w-full object-cover rounded-lg blur"
                                    alt={post.name}
                                />


                                <div className="grid grid-cols-3   mx-auto mt-3 md:w-[20%]">

                                    <FacebookShareButton
                                        url={fullUrl}
                                        hashtag={'#مؤسسة الخير'}
                                    >
                                        <Facebook/>
                                    </FacebookShareButton>

                                    <TwitterShareButton
                                        url={fullUrl}
                                        title={post.name}
                                    >
                                        <Twitter/>
                                    </TwitterShareButton>

                                    <button onClick={() => shareWhatsapp(post.name, fullUrl)}>
                                        <WhatsappBlack/>
                                    </button>
                                </div>


                                <div className="mt-8 text-base  text-zinc-900 max-md:max-w-full">
                                    <div dangerouslySetInnerHTML={{__html: post.description}}/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="md:col-span-1 col-span-1 max-h-full">
                        <DonationCardSidbar
                            post={post}
                            price={price}

                            setShowPopup={setShowPopup}
                            translations={translations}
                        />
                        <SideBar projectId={post.id} projectShow={true} percentage={50} symbol={"USD"}/>
                    </div>
                </div>
            </div>
            {showPopup && (
                <PopupComponent price={price} type={type} setShowPopup={setShowPopup} projectId={post.id} symbol={"USD"}/>
            )}
        </>
    );
}

export default OrphanShow;
