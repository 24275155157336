
import React from 'react';
import { useSelector } from 'react-redux';
import { translateur } from '../Managment/Translate';


export const translateText = (word, translations) => {
    const translate = translateur;
    return word ? translate(word)(translations) : '';
};

const TranslateComponent = ({ word }) => {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const translatedWord = translateText(word, translations);
    return <>{translatedWord}</>;
};

export default TranslateComponent;
