import React, {useEffect, useRef, useState} from 'react';
import {SearchIcon} from "../../Images/svgs";
import {Link} from "react-router-dom";
import {truncateText} from "../../axios-client";
import {useDispatch, useSelector} from "react-redux";
import {changeLoaderStatus, feachSerch, handelSerchStatus, resetSerchState} from "../../Managment/SerchResult";
import TranslateComponent, { translateText } from '../TranslateComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function SearchComponent({phone = false}) {
    const [word, setWord] = useState('');
    const [choseCompaign, setChoseCompaign] = useState('all');

    const compaign = [
        {label: "projects"},
        {label: "campaigns"},
        {label: "all"}
    ];

    const [campaign, setCampaign] = useState('');
    const compaignData = useSelector(state => state.StoreSerch.content);
    const showDropdown = useSelector(state => state.StoreSerch.ShowDropdown);
    const loader =useSelector(state => state.StoreSerch.loader);
    const RefForSerchDrop = useRef(null);
    const refForCampaign =useRef(null);
    // const handleOptionClick = (option) => {
    //     dispatch(handelSerchStatus(false));
    // };
    const [timeEnd, setTimeEnd] = useState(false);

    const [searchWord,setSearchWord]=useState('');
    const dispatch = useDispatch();

    const getComapignData = (searchWord, campaign) => {
        if (searchWord) {
            if (campaign === 'projects' || campaign === 'campaigns') {
                setCampaign(campaign);
            } else {
                setCampaign('all');
            }
            setSearchWord(searchWord);
            setTimeEnd(true);
        }
    };

    useEffect(() => {
        if (searchWord && timeEnd && campaign) {
            dispatch(feachSerch(searchWord, campaign));
            setTimeEnd(false);
        }
    }, [searchWord,timeEnd,campaign]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (word) {
                getComapignData(word, choseCompaign);
            }
        }, 2000);

        return () => {
            clearTimeout(handler);
        };
    }, [word, choseCompaign]);

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setWord(value);
        dispatch(resetSerchState([]));
        dispatch(changeLoaderStatus(true));
        dispatch(handelSerchStatus(true));

        if (value === '' && showDropdown) {
            dispatch(handelSerchStatus(false));
        }
    };

    const clearSearchData = () => {
        setWord('');
        dispatch(handelSerchStatus(false));
    };

    const [showDropCompaign, setShowDropCompaign] = useState(false);
    useEffect(() => {
        const handelCloseSercDrop = (event) => {
            if (RefForSerchDrop.current && !RefForSerchDrop.current.contains(event.target)) {
                dispatch(handelSerchStatus(false));
            }
        }

        window.addEventListener('mousedown', handelCloseSercDrop);
        return () => {
            window.removeEventListener('mousedown', handelCloseSercDrop)
        }
    }, []);

    useEffect(()=>{
        const handelCloseSercDrop = (event) => {
            if (refForCampaign.current && !refForCampaign.current.contains(event.target)) {
                setShowDropCompaign(false);
            }
        }
        window.addEventListener('mousedown', handelCloseSercDrop);
        return () => {
            window.removeEventListener('mousedown', handelCloseSercDrop)
        }
    },[])

    return (
        <div
            className={` ${phone?"relative ":''}flex gap-5 justify-between self-stretch px-3.5 py-2 my-auto text-sm leading-5 bg-white border border-solid shadow-sm border-[color:var(--Gray-300,#D0D5DD)] rounded-[36px]`}>
            <div className="flex gap-2 justify-between text-zinc-500">
                <div className="w-5 aspect-square">
                    <SearchIcon/>
                </div>
                <input className='outline-none w-16 md:w-32' value={word} onChange={handleChangeInput}
                       placeholder={translateText('search')}/>
                {showDropdown && word !== '' && (
                        <ul ref={RefForSerchDrop} className="z-[50] absolute w-[80%] md:w-[15%] ml-[-15px] bg-white border border-gray-300 rounded-b-lg shadow-lg mt-[1.8rem] border-t-0 max-h-80 overflow-y-auto">
                        {choseCompaign ==='all'?(
                            <>
                                {compaignData?.data?.projectresource.slice(0, 3).map((item, index) => (
                                    <Link
                                        to={`/project/${item.slug}`}
                                        key={index}>
                                        <li role="button"
                                            className="py-1 px-2 hover:bg-gray-100 flex gap-2 items-center">
                                            <div className="w-[50px] bg-blue-700 border-1 rounded-lg">
                                                <LazyLoadImage src={item.main_image}
                                                    className="h-[50px] w-[50px] bg-cover rounded-lg"
                                                    alt={item.name}/>
                                            </div>
                                            <span className="hover:text-black">
                                                {item?.name?.length && (
                                                    truncateText(item.name, item.name.length > 20 ? 20 : item.name.length)
                                                )}
                                            </span>
                                        </li>
                                    </Link>
                                ))}
                                {compaignData?.data?.campaignresource.slice(0, 3).map((item, index) => (
                                    <Link
                                        to={`/campaign/${item.slug}`}
                                        key={index}>
                                        <li role="button"
                                            className="py-1 px-2 hover:bg-gray-100 flex gap-2 items-center">
                                            <div className="w-[50px] bg-blue-700 border-1 rounded-lg">
                                                <LazyLoadImage src={item.main_image}
                                                    className="h-[50px] w-[50px] bg-cover rounded-lg"
                                                    alt={item.name}/>
                                            </div>
                                            <span className="hover:text-black">
                                                {item?.name?.length && (
                                                    truncateText(item.name, item.name.length > 20 ? 20 : item.name.length)
                                                )}
                                            </span>
                                        </li>
                                    </Link>
                                ))}
                                {compaignData?.data?.postresource.slice(0, 3).map((item, index) => (
                                    <Link
                                        to={`/${item.slug}`}
                                        key={index}>
                                        <li role="button"
                                            className="py-1 px-2 hover:bg-gray-100 flex gap-2 items-center">
                                            <div className="w-[50px] bg-blue-700 border-1 rounded-lg">
                                                <LazyLoadImage src={item.image}
                                                    className="h-[50px] w-[50px] bg-cover rounded-lg"
                                                    alt={item.label}/>
                                            </div>
                                            <span className="hover:text-black">
                                                {item?.label?.length && (
                                                    truncateText(item.label, item.label.length > 20 ? 20 : item.label.length)
                                                )}
                                            </span>
                                        </li>
                                    </Link>
                                ))}
                            </>
                       ):(
                        compaignData.map((item, index) => (
                            <Link
                                to={`/project/${item.slug}`}
                                // to={`${campaign === 'projects' ? 'project' : campaign}/${item.slug}`}
                                key={index}>
                                <li role="button"
                                    // onClick={() => handleOptionClick(item?.label ? item?.label : item.name)}
                                    className="py-1 px-2 hover:bg-gray-100 flex gap-2 items-center">
                                    <div className="w-[50px] bg-blue-700 border-1 rounded-lg">
                                        <LazyLoadImage src={item.main_image}
                                             className="h-[50px] w-[50px] bg-cover rounded-lg"
                                             alt={item.name}
                                        />
                                    </div>
                                    <span className="hover:text-black">
                                        {item?.name?.length && (
                                            truncateText(item.name, item.name.length > 20 ? 20 : item.name.length)
                                        )}
                                    </span>
                                </li>
                            </Link>
                        ))
                       )}

                        {loader&&(
                            <div className="flex justify-center items-center">
                                <span className="text-black loading loading-dots loading-md"></span>
                            </div>
                        )}
                        {/* {compaignData.length>0 || compaignData.blog?.data?.length > 0 ||compaignData.campaign?.data?.length > 0 || compaignData.projects?.data?.length > 0 && ( */}
                            <Link to={`/search-result/${choseCompaign}?search=${word}`} role="button"
                            onClick={clearSearchData}
                            className="sticky text-white bg-blue-700 bottom-0 p-[5px] flex justify-center w-full text-[1rem] py-1 hover:bg-blue-500">
                            <TranslateComponent word={'view all'}/>
                            </Link>
                        {/* )} */}
                    </ul>
                )}
            </div>

            <div className="flex gap-2 justify-end">
                <div className="text-zinc-300">|</div>
                <div className='flex flex-row cursor-pointer '>
                    <div className="dropdown dropdown-right dropdown-end">
                        <div tabIndex={0} role={"button"} onClick={() => {
                            setShowDropCompaign(!showDropCompaign);
                        }}
                             className="w-[5rem] text-cyan-950">{choseCompaign ? <TranslateComponent word={choseCompaign}/>  : 'الحملات'}</div>
                        {showDropCompaign && (
                            <ul ref={refForCampaign} tabIndex={0}
                                className="p-2 shadow-lg menu dropdown-content z-[1] bg-white rounded-lg w-52 absolute mt-1 left-0">
                                {compaign?.map((item, index) => (
                                    <li key={index} role="button" onClick={() => {
                                        setShowDropCompaign(!showDropCompaign)
                                        setChoseCompaign(item.label);
                                        getComapignData(word, item.label);
                                    }}
                                        className="py-2 px-4 hover:bg-gray-100 rounded-lg cursor-pointer text-black">
                                        <TranslateComponent word={item.label}/>
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                </div>
            </div>
        </div>

    );
}

export default SearchComponent;