import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const ZakatTable = createSlice({
    name: 'StoreZakatTable',
    initialState:{
        zakatData:[],
        loader:false,
        zakatTyps:[],
    }
    ,
    reducers:{
        getZakatData:(state,action)=>{
            state.zakatData=action.payload;
        },
        changeLoaderStatus:(state,action)=>{
            state.loader=action.payload;
        },
        getZakatTypes:(state,action)=>{
            state.zakatTyps=action.payload;
        }
    }
})
export const {getZakatData ,changeLoaderStatus,getZakatTypes} =ZakatTable.actions

export const fetchGetZakatData =(zakatType=null) => async(dispatch) =>{
    axiosClient.get('/my-zakats',{
        params:{
            zakatType:zakatType
        }
    })
    .then(response=>{
        dispatch(getZakatData(response.data.data.zakatHistory));
        dispatch(getZakatTypes(response.data.data.zakat));
        dispatch(changeLoaderStatus(false));
    })
    .catch(error=>{
        console.log('ERROR :' + error)
        dispatch(changeLoaderStatus(false))
    })
}

export const fetchFilterZakatData =(startDate,endDate) => async(dispatch) =>{
    axiosClient.get('/my-zakats',{
        params:{
            startDate:startDate,
            endDate:endDate,
        }
    })
    .then(response=>{
        dispatch(getZakatData(response.data.data.zakatHistory));
        dispatch(getZakatTypes(response.data.data.zakat));
        dispatch(changeLoaderStatus(false));
    })
    .catch(error=>{
        console.log('ERROR :' + error)
        dispatch(changeLoaderStatus(false))
    })
}

export default ZakatTable.reducer;