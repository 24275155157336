import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const Footer = createSlice({

  name:"StoreFooter",
  initialState:{
    data:[],
    loader:false,
  },
  reducers:{
    UpdateDataState:(state,action)=>{
      state.data=action.payload;
    },
    UpdateLoaderState:(state,action)=>{
      state.loader=action.payload;
    }
  }

});

export const {UpdateDataState,UpdateLoaderState}=Footer.actions;

export const featchFooter=(dispatch)=>{
  dispatch(UpdateLoaderState(true));
  axiosClient.get('/footer')
  .then(response => {
    dispatch(UpdateDataState(response.data));
      dispatch(UpdateLoaderState(false));
  })
  .catch(error => {
      console.error('Error fetching footer data:', error);
      dispatch(UpdateLoaderState(false));
  });
}

export default Footer.reducer;