import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import {getToken} from './axios-client';
import PaymentComponent from "./Components/PaymentSteps/PaymentComponent";
import SuccessResultDirect from "./Components/Payment/SuccessResultDirect";
import {CanLogin,CanGoToPayment} from "./Components/MiddlewareForRoute";
import ContactForm from "./Components/Forms/ContactForm";
import PartnerForm from "./Components/Forms/PartnerForm";
import OrphanDetails from "./Components/Orphan/OrphanDetails";
import ErrorResultDirect from "./Components/Payment/ErrorResultDirect";
import ProjectsAll from "./Components/Projects/ProjectsAll";
import ErrorBoundary from './ErrorBoundary';
import SliderShow from './Components/SliderShow';
import OrphanShow from "./Components/Orphan/OrphanShow";
import Kafalat from "./Components/Kafalat";
import KafalaePage from "./Components/Kafalat/KafalaePage";
// Lazy Imports for Components
const Home = React.lazy(() => import("./Components/Home"));
const VolunteerForm = React.lazy(() => import("./Components/Forms/VolunteerForm"));
const SerchResult = React.lazy(() => import("./Components/SerchResult"));
const AnyPages = React.lazy(() => import("./Components/AnyPages"));
const Login = React.lazy(() => import("./Components/Login"));
const Register = React.lazy(() => import("./Components/Register"));
const Profile = React.lazy(() => import("./Components/Profile"));
const ForgotPassword = React.lazy(() => import("./Components/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./Components/ResetPassword"));
const NotFoundPage = React.lazy(() => import("./Components/NotFoundPage"));
const MainLayout = React.lazy(() => import("./Components/Layouts/MainLayout"));
const CustomLayout = React.lazy(() => import("./Components/Layouts/CustomLayout"));
const Blog = React.lazy(() => import("./Components/Pages/blog/Blog"));
const BlogPost = React.lazy(() => import("./Components/Pages/blog/BlogPost"));
const Project = React.lazy(() => import("./Components/Pages/projects/Project"));
const SuccessStory = React.lazy(() => import("./Components/Pages/SuccessStories/SuccessStory"));
const SuccessStoryPost = React.lazy(() => import("./Components/Pages/SuccessStories/SuccessStoryPost"));
const RencetNews = React.lazy(() => import("./Components/Pages/RencetNews"));
const NewsPost = React.lazy(() => import("./Components/Pages/NewsPost"));
const Country = React.lazy(() => import("./Components/Pages/Country"));
const AboutUs = React.lazy(() => import("./Components/Pages/AboutUs"));
const OurStory = React.lazy(() => import('./Components/Pages/OurStory'));
const Vision = React.lazy(() => import("./Components/Pages/Vision"));
const WeInNumbers = React.lazy(() => import("./Components/Pages/WeInNumbers"));
const OurOffice = React.lazy(() => import("./Components/Pages/OurOffice"));
const MostQuestioned = React.lazy(() => import("./Components/BuilderComponents/Faq/MostQuestioned"));
const Privacy = React.lazy(() => import("./Components/Pages/Privacy"));
const Gift = React.lazy(() => import("./Components/Pages/Gift"));
const ZacCalc = React.lazy(() => import("./Components/Pages/ZacCalc"));
const SocialMedia = React.lazy(() => import("./Components/Pages/SocialMedia"));
const MediaReleases = React.lazy(() => import("./Components/Pages/MediaReleases"));
const Events = React.lazy(() => import("./Components/Pages/Events"));
const WhatDidTheySay = React.lazy(() => import("./Components/Pages/WhatDidTheySay"));
const Builder = React.lazy(() => import("./Components/Builder"));
const BasketComponent = React.lazy(() => import("./Components/Cart/BasketComponent"));

// Helper function for protected routes
const ProtectedRoute = ({ children }) => {
    return getToken() ? children : <Navigate to="/login" />;
};

// Helper function for public-only routes
const PublicOnlyRoute = ({ children }) => {
    return getToken() ? <Navigate to="/" /> : children;
};
window.addEventListener('dontHaveCart',()=>{
    return <Navigate to="/" />;
})

const routes = [
    { path: "/", element: <Home />,handle: { crumb: () => "Home" } },
    {
        path: "/:any",
        element: <AnyPages />,

        handle: {
            crumb: (any) =>  `${any?.replace(/-/g, ' ') }`
        }
    },
    {
        path: "/campaign/:any", // Update the path to include '/campaign/'
        element: <AnyPages url={'campaign/'}/>,
        handle: {
            crumb: (any) =>  `${any?.replace(/-/g, ' ') }`
        }
    },
    {
        path: "/builder",
        element: <Builder />,
        handle: {
            crumb: () => "builder"
        }
    },

    {
        path: "/",
        element: <MainLayout />,
        children: [

            { path: "login", element: <PublicOnlyRoute><Login /></PublicOnlyRoute>,  handle: { crumb: () => "Login" } },
            { path: "register", element: <PublicOnlyRoute><Register /></PublicOnlyRoute>, handle: { crumb: () => "Register" } },
            { path: "forgot-password", element: <PublicOnlyRoute><ForgotPassword /></PublicOnlyRoute>, handle: { crumb: () => "Forgot Password" } },
            { path: "reset-password", element: <PublicOnlyRoute><ResetPassword /></PublicOnlyRoute>, handle: { crumb: () => "Reset Password" } },
            { path: "profile", element: <ProtectedRoute><Profile /></ProtectedRoute>, handle: { crumb: () => "Profile" } },
            { path: "country/:slug", element: <Country />, handle: { crumb: () => "Country" } },
            { path: "about-us", element: <AboutUs />, handle: { crumb: () => "About Us" } },
            { path: "our-story", element: <OurStory />, handle: { crumb: () => "Our Story" } },
            { path: "vision", element: <Vision />, handle: { crumb: () => "Vision" } },
            { path: "we-in-numbers", element: <WeInNumbers />, handle: { crumb: () => "We In Numbers" } },
            { path: "our-office", element: <OurOffice />, handle: { crumb: () => "Our Office" } },
            { path: "most-questioned", element: <MostQuestioned />, handle: { crumb: () => "common questions" } },
            { path: "privacy", element: <Privacy />, handle: { crumb: () => "Privacy" } },
            // { path: "zaka-calculator", element: <ZacCalc />, handle: { crumb: () => "Zac Calculator" } },
            { path: "cart", element:<CanGoToPayment Component={BasketComponent}/>, handle: { crumb: () => "carts"}},
            { path: "payment", element: <CanLogin Component={PaymentComponent} />, handle: { crumb: () => "payment"}},
            { path: "/success/direct", element:<CanLogin Component={SuccessResultDirect}/>, handle: { crumb: () =>"Success"}},
            { path: "/error/direct", element: <CanLogin Component={ErrorResultDirect}/>, handle: { crumb: () =>"Error"}},
            { path: "search-result/:campaign/" , element: <SerchResult/>,handle:{  crumb: (campaign) => `${campaign?.replace(/-/g, ' ') }`}},
            { path: "volunteer-form" ,element:<VolunteerForm/>,handle:{crumb:()=>"Volunteer Form"}},
            { path:"/contact-us",element: <ContactForm/>,handle: {crumb:()=>"Contact Form"}},
            { path:"partner-form",element: <PartnerForm/>,handle: {crumb:()=>"Partner Form"}},
            { path:"orphan-details",element: <OrphanDetails/>,handle: {crumb:()=>"Orphan Details"}},
            { path:"more-gallery",element:<SliderShow/>,handle: {crumb:()=>"Galleries"}},
            { path: "/Kafalat",element: <Kafalat/>, handle: { crumb: () =>"kafalat"}},

            { path: "social-media", element: <SocialMedia />, handle: { crumb: () => "communication Web-sites" } },
            { path: "media-releases", element: <MediaReleases />, handle: { crumb: () => "Media releases" } },
            { path: "events", element: <Events />, handle: { crumb: () => "Events and Occasions" } },
            { path: "spoken", element: <WhatDidTheySay />, handle: { crumb: () => "Testimonials they said about us" } },

            {
                path: "/kafale/:slug",
                element:<KafalaePage/>,
                handle: {
                    crumb: (slug) => `${slug?.replace(/-/g, ' ') }`
                }
            },
            {
                path: "/orphan/:slug",
                element:<OrphanShow/>,
                handle: {
                    crumb: (slug) => `${slug?.replace(/-/g, ' ') }`
                }
            },
            {
                path: '/projects',
                element: <ProjectsAll/>,
                handle: {
                    crumb: () => "Projects"
                },
            },
            {
                path: '/project/:slug',
                element: <Project  />,
                handle: {
                    crumb: (slug) => `${slug?.replace(/-/g, ' ') }`
                }
            },
            {
                path: '/blog',
                element: <Blog />,
                handle: {
                    crumb: () => "blog"
                },
                children: [
                    {
                        path: '/blog/:slug',
                        element: <BlogPost  />,
                        handle: {
                            crumb: (slug) => `${slug?.replace(/-/g, ' ') }`
                        }
                    },
                ]
            },
            {
                path: "success-stories",
                element: <SuccessStory />,
                handle: { crumb: () => "success stories" },
                children: [
                    {
                        path: "/success-stories/:slug",
                        element: <SuccessStoryPost />,
                        handle: {
                            crumb: (slug) => `${slug?.replace(/-/g, ' ') }`
                        }
                    }
                ]
            },
            {
                path: "alkhiar-news",
                element: <RencetNews />,
                handle: { crumb: () => "Alkhir News"},
                children: [
                    {
                        path: "/alkhiar-news/:newsId",
                        element: <NewsPost />,
                        handle: { crumb: ({ newsId }) => `${newsId}` }
                    }
                ]
            },

        ]
    },
    {
        path: "/",
        element: <CustomLayout />,
        children: [

            { path: "gift", element: <Gift />, handle: { crumb: () => "Gift" } }
        ]
    },
    {
        path: "*",
        element: <NotFoundPage />,
        handle: { crumb: () => "404" }
    },
    {
        path: "/404",
        element: <NotFoundPage />,
        handle: { crumb: () => "404" },

    },
];

const addErrorBoundary = (route) => ({
    ...route,
    errorElement: <ErrorBoundary/>,
});

const Router = createBrowserRouter(
    routes.map((route) => addErrorBoundary(route))
);

export default Router;
