import { createContext, useContext, useState } from "react";



const StateContext = createContext({
    data: {},
    setData: () => { },
    projectData: {},
    setprojectData: () => { },
    setDonationsData: () => { },
    setTotalProjectData: () => { },
    setCountryData: () => { },
    selectedItems: {},
    setSelectedItems: () => { },
})

export const ContextProvider = ({ children }) => {

    const initialData = {
        content: [],
        root: {},
    }

    const [data, setData] = useState(initialData)
    const [projectData, setprojectData] = useState([])
    const [donations, setDonationsData] = useState([])
    const [totalProject, setTotalProjectData] = useState([])
    const [country, setCountryData] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);


    return (
        <StateContext.Provider value={{
            data,
            setData,
            projectData,
            setprojectData,
            setDonationsData,
            donations,
            setTotalProjectData,
            totalProject,
            setCountryData,
            country,
            selectedItems,
            setSelectedItems
        }}>
            {children}
        </StateContext.Provider>
    )
}


export const useStateContext = () => useContext(StateContext);