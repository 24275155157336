import React from 'react';
import { useMatches, Link, useParams } from 'react-router-dom';
import { HomeIcon, RightArrow } from '../../Images/svgs'
import {truncateText} from "../../axios-client";
import {useMediaQuery} from "react-responsive";

const useBreadcrumbs = () => {
    const matches = useMatches();
    const params = useParams(); // Use useParams to get params

    const breadcrumbs = matches.map(({ path, handle }) => ({
        path,
        crumb: handle && handle.crumb ? handle.crumb(params[Object.keys(params)[0]]) : null,
    }));

    return breadcrumbs;
};

const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();
    const isTabletScreen = useMediaQuery({ query: '(min-width: 600px) and (max-width: 786px)' });
    return (
        <>
            <nav className='hidden md:flex flex-col justify-center items-end px-16 py-4 text-sm font-medium text-right text-white bg-blue-600 max-md:px-5'>
                <ul className='container max-w-7xl mx-auto md:flex md:flex-row items-center self-start gap-4 px-1.5 py-1'>
                    <Link to='/'><HomeIcon /></Link>
                    <RightArrow />
                    {breadcrumbs
                        .filter(breadcrumb => breadcrumb.crumb)
                        .map((breadcrumb, index, array) =>
                        {
                            return (
                                <React.Fragment key={index}>
                                    <li>
                                        <Link className='grow whitespace-nowrap' to={breadcrumb.path}>
                                            {isTabletScreen ?(
                                                truncateText(breadcrumb.crumb, 10)
                                            ):(
                                                breadcrumb.crumb
                                            )}
                                        </Link>
                                    </li>
                                    {index !== array.length - 1 && <RightArrow />}
                                </React.Fragment>
                            )
                        })}
                </ul>
            </nav>
        </>
    );
};

export default Breadcrumbs;
