import React, { useEffect, useState } from 'react';
import axiosClient from "../../axios-client";
import {LazyLoadImage} from "react-lazy-load-image-component";
import TranslateComponent, { translateText } from '../TranslateComponent';
import { useSelector,useDispatch } from 'react-redux';
import { featchFooter } from '../../Managment/Footer';
import {toast} from "react-toastify";
function Footer() {
    const header =useSelector(state=>state.StoreHeader.Headers);

    const footerData = useSelector(state=>state.StoreFooter.data);
    const isLoading =useSelector(state=>state.StoreFooter.loader);
    const translations = useSelector((state) => state.Storetranslate.translations);
    const dispatch =useDispatch();
    const [email, setEmail] = useState('');
    useEffect(() => {
        dispatch(featchFooter);
    }, []);

    if (isLoading) {
        return <div className={'flex justify-center'}><TranslateComponent word={'Loading'}/></div>;
    }

    if (!footerData) {
        return <div className={'flex justify-center'}><TranslateComponent word={'No data available'}/></div>;
    }



    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await axiosClient.post('/email/save', { email });
            toast.success(translateText('Successfully',translations) )
            setEmail('')
        } catch (error) {
            toast.error( translateText('Error',translations))
            console.error('Error sending email:', error);
        }
    };

    return (
        <footer className=" pt-10 bg-neutral-50 border-t border-neutral-200 mt-10 ">
            <div className="container max-w-7xl mx-auto px-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-20 items-center">
                    <div className="grid gap-6">
                        <div className="relative">
                            <div>
                                <LazyLoadImage src={header.logo_header} className={'h-[60px]'} alt='logo'/>
                            </div>
                        </div>
                        <div className="text-black text-sm leading-7">
                            <TranslateComponent word={'Al-Khair Foundation was established in 2003, focuses on aid and development, operates globally, is committed to transparency and sustainability, and participates in development in the Middle East.'}/>
                        </div>
                        <div className="grid grid-cols-1  sm:grid-cols-2  gap-4">
                            <div className="grid gap-3   items-center">
                                <div className="w-12 h-12 bg-neutral-200 rounded-full grid place-items-center">
                                    <svg
                                        width="48"
                                        height="49"
                                        viewBox="0 0 48 49"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect y="0.704224" width="48" height="48" rx="24" fill="#E7E7E7" />
                                        <path
                                            d="M28.168 31.7875H19.8346C17.3346 31.7875 15.668 30.5375 15.668 27.6209V21.7875C15.668 18.8708 17.3346 17.6208 19.8346 17.6208H28.168C30.668 17.6208 32.3346 18.8708 32.3346 21.7875V27.6209C32.3346 30.5375 30.668 31.7875 28.168 31.7875Z"
                                            stroke="#163A8F"
                                            strokeWidth="1.2"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M28.1654 22.2042L25.557 24.2876C24.6987 24.9709 23.2904 24.9709 22.432 24.2876L19.832 22.2042"
                                            stroke="#163A8F"
                                            strokeWidth="1.2"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="grid gap-0.5">
                                    <div className="text-neutral-900 text-[15px] font-normal"><TranslateComponent word={'Email us at'}/></div>
                                    <div className="text-neutral-900 text-[15px] font-medium">
                                        <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${footerData.data?.email}`}
                                           target="_blank" rel="noopener noreferrer">
                                            {footerData.data?.email}
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <div className="grid gap-3   items-center">
                                <div className="w-12 h-12 bg-neutral-200 rounded-full grid place-items-center">
                                    <svg
                                        width="48"
                                        height="49"
                                        viewBox="0 0 48 49"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect y="0.704224" width="48" height="48" rx="24" fill="#E7E7E7" />
                                        <path
                                            d="M31.3096 30.9792C31.3096 31.2792 31.243 31.5875 31.1013 31.8875C30.9596 32.1875 30.7763 32.4709 30.5346 32.7375C30.1263 33.1875 29.6763 33.5125 29.168 33.7209C28.668 33.9292 28.1263 34.0375 27.543 34.0375C26.693 34.0375 25.7846 33.8375 24.8263 33.4292C23.868 33.0209 22.9096 32.4709 21.9596 31.7792C21.0013 31.0792 20.093 30.3042 19.2263 29.4458C18.368 28.5792 17.593 27.6709 16.9013 26.7209C16.218 25.7709 15.668 24.8209 15.268 23.8792C14.868 22.9292 14.668 22.0208 14.668 21.1542C14.668 20.5875 14.768 20.0458 14.968 19.5458C15.168 19.0375 15.4846 18.5708 15.9263 18.1542C16.4596 17.6292 17.043 17.3708 17.6596 17.3708C17.893 17.3708 18.1263 17.4208 18.3346 17.5208C18.5513 17.6208 18.743 17.7708 18.893 17.9875L20.8263 20.7125C20.9763 20.9208 21.0846 21.1125 21.1596 21.2958C21.2346 21.4708 21.2763 21.6458 21.2763 21.8042C21.2763 22.0042 21.218 22.2042 21.1013 22.3958C20.993 22.5875 20.8346 22.7875 20.6346 22.9875L20.0013 23.6458C19.9096 23.7375 19.868 23.8459 19.868 23.9792C19.868 24.0459 19.8763 24.1042 19.893 24.1708C19.918 24.2375 19.943 24.2875 19.9596 24.3375C20.1096 24.6125 20.368 24.9709 20.7346 25.4042C21.1096 25.8375 21.5096 26.2792 21.943 26.7209C22.393 27.1625 22.8263 27.5708 23.268 27.9458C23.7013 28.3125 24.0596 28.5625 24.343 28.7125C24.3846 28.7292 24.4346 28.7542 24.493 28.7792C24.5596 28.8042 24.6263 28.8125 24.7013 28.8125C24.843 28.8125 24.9513 28.7625 25.043 28.6709L25.6763 28.0459C25.8846 27.8375 26.0846 27.6792 26.2763 27.5792C26.468 27.4625 26.6596 27.4042 26.868 27.4042C27.0263 27.4042 27.193 27.4375 27.3763 27.5125C27.5596 27.5875 27.7513 27.6959 27.9596 27.8375L30.718 29.7959C30.9346 29.9459 31.0846 30.1208 31.1763 30.3292C31.2596 30.5375 31.3096 30.7459 31.3096 30.9792Z"
                                            stroke="#163A8F"
                                            strokeWidth="1.2"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M28.4167 23.2043C28.4167 22.7043 28.025 21.9376 27.4417 21.3126C26.9083 20.7376 26.2 20.2876 25.5 20.2876"
                                            stroke="#163A8F"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M31.3333 23.2042C31.3333 19.9792 28.725 17.3708 25.5 17.3708"
                                            stroke="#163A8F"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="grid gap-0.5">
                                    <div className="text-neutral-900 text-[15px] font-normal"><TranslateComponent
                                        word={'Do you have a question'}/></div>
                                    <div className="text-neutral-900 text-[15px] font-medium">
                                        <a href={`https://wa.me/${footerData.data?.phone}`} target="_blank"
                                           rel="noopener noreferrer">
                                            {footerData.data?.phone}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-7 rounded-2xl grid gap-6 items-end">
                        <div className="grid gap-6">
                            <div className="grid gap-1">
                                <div className="text-neutral-900 text-xl font-medium capitalize">
                                    <TranslateComponent word={'the news'}/>
                                </div>
                                <div className="text-neutral-900 text-base font-normal">
                                    <TranslateComponent
                                        word={'Subscribe to the newsletter to receive everything new from us'}/>

                                </div>
                            </div>
                            <div className="grid grid-cols-5 gap-3 dark:bg-white rounded-lg border-2 items-end">
                                <div className="relative col-span-3  md:col-span-3 lg:col-span-4">
                                    <svg
                                        className="absolute top-1/2 transform -translate-y-1/2 right-4 w-5 h-5 text-gray-400"
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.66797 6.03748L8.47207 10.8003C9.02304 11.186 9.29853 11.3789 9.59819 11.4536C9.86288 11.5195 10.1397 11.5195 10.4044 11.4536C10.7041 11.3789 10.9796 11.186 11.5305 10.8003L18.3346 6.03748M5.66797 16.8708H14.3346C15.7348 16.8708 16.4348 16.8708 16.9696 16.5983C17.44 16.3586 17.8225 15.9762 18.0622 15.5058C18.3346 14.971 18.3346 14.2709 18.3346 12.8708V7.53748C18.3346 6.13734 18.3346 5.43728 18.0622 4.9025C17.8225 4.43209 17.44 4.04964 16.9696 3.80996C16.4348 3.53748 15.7348 3.53748 14.3346 3.53748H5.66797C4.26784 3.53748 3.56777 3.53748 3.03299 3.80996C2.56259 4.04964 2.18014 4.43209 1.94045 4.9025C1.66797 5.43728 1.66797 6.13734 1.66797 7.53748V12.8708C1.66797 14.2709 1.66797 14.971 1.94045 15.5058C2.18014 15.9762 2.56259 16.3586 3.03299 16.5983C3.56777 16.8708 4.26784 16.8708 5.66797 16.8708Z"
                                            stroke="#BBBEDA"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <input
                                        className="rounded-lg pl-10 py-2 placeholder-gray-400 indent-10 w-full focus:border-none"
                                        placeholder="Please write your email here"
                                        value={email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <button
                                    className="text-black text-sm col-span-2 lg:col-span-1 font-bold bg-[#FFCD50] rounded-lg px-2 py-1.5 m-1"
                                    onClick={handleSubmit}
                                >
                                    <TranslateComponent word={'Subscribe'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 justify-center w-full gap-6 mt-20 mb-5">
                    {footerData.data?.footer.map((section, index) => (
                        <ul key={index} className="col-span-1 text-center md:text-start gap-6">
                            <li className="text-neutral-900 text-xl font-medium leading-none tracking-wide mb-3">
                                {section.text}
                            </li>
                            {section.children && section.children.map((child, childIndex) => (
                                <li key={childIndex} className="text-neutral-900 text-base font-normal my-2">
                                    <a href={child.page ? `/${child.page.slug}` : child.href || "#"}> {child.text}</a>

                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
                <div
                    className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 py-5 border-t-2 border-neutral-200 items-center">
                    <div className="text-center md:text-right text-neutral-900 text-sm font-normal">
                        <TranslateComponent word={'All rights reserved to Al-Khair Foundation @2023'}/>


                    </div>
                    <div className="flex justify-center md:justify-start items-start gap-10">
                        <a target={'_blank'} href={footerData.data?.policy}
                           className="text-black text-[13px] font-medium">
                            <TranslateComponent word={'Individual property rights'}/>
                        </a>
                        <a target={'_blank'} href={footerData.data?.terms}
                           className="text-black text-[13px] font-medium">
                            <TranslateComponent word={'Usage Policy'}/>
                        </a>
                    </div>
                    <div className="flex justify-center md:justify-end items-center gap-5">
                        <div className="text-black text-sm font-normal leading-7">
                            <TranslateComponent word={'social networks'}/>
                        </div>
                        <div className="flex gap-5">

                            {footerData.data?.social && footerData.data?.social.map((section, index) => (

                               <a key={index} target={"_blank"} href={section.link}>
                                   <LazyLoadImage
                                       src={section.icon}
                                       alt={'icon'}
                                   />
                               </a>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
