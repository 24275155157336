import { useState, useEffect } from 'react';
import axiosClient from "../../../axios-client";


const useFetchOrphan = (slug) => {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrphan = async () => {
            try {
                // eslint-disable-next-line no-undef
                const response = await axiosClient.get(`orphan-details/${slug}`);
                setPost(response.data.data);
            } catch (err) {
                setError('Error fetching the orphan data');
            } finally {
                setLoading(false);
            }
        };
        fetchOrphan();
    }, [slug]);

    return { post, loading, error };
};

export default useFetchOrphan;
