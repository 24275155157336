import React, { useState, useEffect } from 'react';
import axiosClient from "../../axios-client";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TranslateComponent from "../TranslateComponent";

const ProjectCard = React.lazy(() => import("./ProjectCard"));
const Campaigns = React.lazy(() => import("../Campaigns"));

function SideBar({projectId,percentage,projectShow =false}) {
    // Define state variables
    const [project, setProject] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await axiosClient.get('/sidebar/post');
                const { project, campaign, media } = response.data.data;
                setProject(project);
                setCampaign(campaign);
                setMedia(media);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Handle loading and error states
    if (loading) return <div><TranslateComponent word={'Loading'}/></div>;
    if (error) return <div>Error: {error}</div>;




    return (
        <div className="w-full">

            {/* Media Gallery */}
            {media.map((item, index) => (
                <LazyLoadImage
                    key={index}
                    loading="lazy"
                    src={item.image}
                    alt={`media-${index}`}
                    className="mb-5 w-full  rounded-xl object-cover"
                />
            ))}


            {campaign && <Campaigns project={campaign}/>}
            {project && <ProjectCard project={project}/>}

</div>
)
    ;
}

export default SideBar;
