import { createSlice } from "@reduxjs/toolkit";

const LoaderPageDownload = createSlice({
  name: 'StoreLoaderPageDownload',
  initialState: {
    loader: false,
  },
  reducers: {
    updateLoaderPageDownload: (state, action) => {
      state.loader = action.payload;
    }
  },
})

export const { updateLoaderPageDownload } = LoaderPageDownload.actions;
export default LoaderPageDownload.reducer;