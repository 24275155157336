import React from 'react';
import { getLocalLang } from "../../../axios-client";
import TranslateComponent from "../../TranslateComponent";

const ReportOrphan =({report})=>{
return(
  <>
  <h1><TranslateComponent word={'ReportOrphan'} /></h1>
  <div className="overflow-x-scroll rounded-xl hide-scroll">
     <table className="w-full whitespace-nowrap ">
          <thead>
              <tr className={'px-5 py-5 font-bold bg-gray-200 rounded-xl border border-solid backdrop-blur-[10px] border-zinc-300 w-full'}>
                  <th className="px-2 py-5 text-start"><TranslateComponent word={'price'}/></th>
                  <th className="px-2 text-start"><TranslateComponent word={'orderStatus'}/></th>
              </tr>
          </thead>
          <tbody className={''}>
          {report?.map((item,index)=>(
              <tr key={index} className={'p-5 mt-2.5 rounded-xl border border-solid backdrop-blur-[10px] bg-neutral-100 border-zinc-300 w-full'}>
                <td className={'px-2 py-5 '}>{item.file_name[`name_${getLocalLang().code}`]}</td>
                <td className={'px-2 '}><a href={item.file}>الملف</a></td>
              </tr>
            ))}
          </tbody>
      </table>
  </div>
  </>
)

}
export default ReportOrphan;