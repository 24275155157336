import {getToken} from "./axios-client";

export const withToken = (fn) => {
    return function(...args) {
        if (getToken()) {
            return fn(...args);
        } else {
            // console.log('No token found');
            return null;
        }
    };
};